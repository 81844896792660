import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'

interface ClienteProps {
   cliente: any | null
   setCliente: (cliente: string | null) => void
   todosClientes: any[]
}

const Cliente: React.FC<ClienteProps> = ({
   cliente,
   setCliente,
   todosClientes
}) => {
   return (
      <div className='my-4'>
         <h2 className='text-xl font-semibold'>Cliente</h2>
         {cliente ? (
            <div className='flex items-center'>
               <span className='mr-4'>
                  {todosClientes.find(c => c.id == cliente)?.full_name ||
                     'Cliente não encontrado'}
               </span>
               <button
                  className='flex items-center rounded bg-red-500 p-2 text-white'
                  onClick={() => setCliente(null)}
               >
                  <FaTrashAlt />
               </button>
            </div>
         ) : (
            <select
               className='mt-2 rounded border p-2'
               onChange={e => setCliente(e.target.value)}
            >
               <option value=''>Selecione um cliente</option>
               {todosClientes.map(c => (
                  <option key={c.id} value={c.id}>
                     {c.full_name}
                  </option>
               ))}
            </select>
         )}
      </div>
   )
}

export default Cliente

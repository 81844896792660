import React, { useState, useEffect } from 'react'
import { ICotacaoCove } from '../../interfaces/ICotacaoCove'
import Footer from '../Footer/footer'
import {
   getProduto,
   getProdutoPrice,
   getProdutos
} from '../../services/produtos/produtosService'
import {
   calculoConsumo,
   calculoKG,
   calculoRendimentoTeoricoKit,
   calculoRendimentoTeoricoL,
   calculoUnidadesKits,
   calculoVolumeKit,
   precoESMlinear,
   precoRSKG,
   precoRSL
} from '../../util/caclculoCove'
import { useNavigate } from 'react-router-dom'
import { IProduct } from '../../interfaces/IProduct'
import { toast, ToastContainer } from 'react-toastify'
import { FaTrashAlt } from 'react-icons/fa'

const initialItem: ICotacaoCove = {
   SKU: [],
   PesoSKU: 0,
   PesoEspecifico: 0,
   VolumeKit: 0,
   Consumo: 0,
   RendTeoricoL: 0,
   RendTeoricoK: 0,
   Kg: 0,
   UnidadesOuKits: 0,
   PrecoKg: 0,
   PrecoML: 0,
   PrecoL: 0,
   idkit: 0,
   idPrice: 0
}

interface IFormData {
   EPS: ''
   h: ''
   R: ''
   mLinear: ''
   PesoEspecifico: 0
   NVV: 0
   PrecoKit: 0
   PesoKit: 0
   Preco: 0
   Componentes: { SKU: string; Valor: number }[]
}

const CotacaoCoveForm = (props: any) => {
   const navigate = useNavigate()
   const { data } = props
   const [item, setItem] = useState<ICotacaoCove>(initialItem)
   const [produto, setProduto] = useState<any>({})
   const [product, setProduct] = useState<any>([])
   const [produtos, setProdutos] = useState<any>([])
   const [formData, setFormData] = useState<IFormData>({
      EPS: '',
      h: '',
      R: '',
      mLinear: '',
      PesoEspecifico: 0,
      NVV: 0,
      PrecoKit: 0,
      PesoKit: 0,
      Preco: 0,
      Componentes: []
   })

   const [componente, setComponente] = useState({ SKU: '', Valor: 0 })

   const getProduct = (productId: number) => {
      if (!productId) {
         return null
      }

      const fetchProdutos = async () => {
         try {
            const response = await getProdutoPrice(productId)
            setProduto({ ...response.product })
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         }
      }
      fetchProdutos()
   }

   const getProducts = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      if (e.target.value === '') {
         return null
      }
      const fetchProdutos = async () => {
         try {
            const response = await getProduto(Number(e.target.value))
            console.log(response)
            setProduct(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         }
      }
      fetchProdutos()
   }

   useEffect(() => {
      const fetchProdutos = async () => {
         try {
            const response = await getProdutos()
            setProdutos(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         } finally {
         }
      }
      fetchProdutos()
   }, [])

   useEffect(() => {
      if (produto) {
         const updatedItem = {
            ...item,
            PesoSKU: produto.peso_sku,
            PesoEspecifico: produto.pesoEspecificoABC
         }
         setItem(updatedItem)
      }
   }, [produto])

   useEffect(() => {
      // console.log('FORMDATA', formData)

      const { EPS, h, R, mLinear, PesoEspecifico, NVV, PesoKit, Preco } =
         formData

      if (EPS && h && R && mLinear) {
         setProduto((prevProduto: any) => ({
            ...prevProduto,
            EPS: parseFloat(EPS),
            h: parseFloat(h),
            R: parseFloat(R),
            mLinear: parseFloat(mLinear),
            pesoEspecificoABC: PesoEspecifico,
            PrecoKit: Preco.toFixed(2),
            PesoKit: PesoKit,
            NVV: NVV,
            Preco: Preco
         }))
         item.VolumeKit = parseFloat(calculoVolumeKit(produto).toFixed(1))
         item.Consumo = parseFloat(calculoConsumo(produto).toFixed(1))
         item.RendTeoricoL = parseFloat(
            calculoRendimentoTeoricoL(produto).toFixed(1)
         )
         item.RendTeoricoK = parseFloat(
            calculoRendimentoTeoricoKit(produto).toFixed(1)
         )
         item.Kg = parseFloat(calculoKG(produto).toFixed(1))
         item.UnidadesOuKits = parseFloat(
            calculoUnidadesKits(produto).toFixed(2)
         )
         item.PrecoKg = parseFloat(precoRSKG(produto).toFixed(2))
         item.PrecoML = parseFloat(precoESMlinear(produto).toFixed(2))
         item.PrecoL = parseFloat(precoRSL(produto).toFixed(2))
         setItem(prevItem => ({
            ...prevItem,
            EPS: parseFloat(EPS),
            h: parseFloat(h),
            R: parseFloat(R),
            mLinear: parseFloat(mLinear),
            PesoEspecifico: parseInt(produto.pesoEspecificoABC),
            PrecoKit: Preco.toFixed(2),
            PesoKit: PesoKit,
            NVV: NVV,
            Preco: Preco,
            SKU: groupSKU(formData.Componentes),
            productId: product.id
         }))
      }
   }, [formData])

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setFormData(prevFormData => ({
         ...prevFormData,
         [name]: value
      }))
   }

   const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setFormData(prevFormData => ({
         ...prevFormData,
         [name]: value
      }))
   }

   const handleComponenteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setComponente(prevComponente => ({
         ...prevComponente,
         [name]: value
      }))
   }

   const sumComponents = (components: { SKU: string; Valor: number }[]) => {
      return components.reduce((total, item) => total + Number(item.Valor), 0)
   }

   const groupSKU = (components: { SKU: string; Valor: number }[]) => {
      return components.reduce((grouped, item) => {
         const formattedValue = String(item.Valor)
            .replace(/^0+/, '')
            .replace(',', '.')
         const skuValueString = `${item.SKU}: ${formattedValue}`
         if (!grouped.includes(skuValueString)) {
            grouped.push(skuValueString)
         }
         return grouped
      }, [] as string[])
   }

   const addComponente = () => {
      if (componente.SKU.trim() === '' || componente.Valor <= 0) {
         toast.error('SKU e Valor não podem estar vazios ou ser iguais a 0.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
         })
         return
      }

      setFormData((prevFormData: any) => {
         const updatedComponents = [...prevFormData.Componentes, componente]
         const updatedPreco = sumComponents(updatedComponents)
         return {
            ...prevFormData,
            Componentes: updatedComponents,
            Preco: updatedPreco
         }
      })
      setComponente({ SKU: '', Valor: 0 })
   }

   const removeComponente = (index: number) => {
      setFormData((prevFormData: any) => {
         const updatedComponents = prevFormData.Componentes.filter(
            (_: any, i: number) => i !== index
         )
         const updatedPreco = sumComponents(updatedComponents)
         return {
            ...prevFormData,
            Componentes: updatedComponents,
            Preco: updatedPreco
         }
      })
   }

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      //await saveCotacaoCove(item)
      //navigate('/home')
      console.log(item)
   }

   return (
      <div className='form-container'>
         <ToastContainer />
         <form onSubmit={handleSubmit} className='space-y-4'>
            <h3 className='text-center text-lg font-bold'>
               Dados do produto - rodape
            </h3>
            <label
               htmlFor='description'
               className='block text-sm font-medium text-gray-700'
            >
               Produto
            </label>
            <select
               name='description'
               id='productId'
               required
               value={product.id}
               onChange={e => getProducts(e)}
               className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            >
               <option value=''>Selecione um produto</option>
               {produtos.map((produto: IProduct) => (
                  <option key={produto.id} value={produto.id}>
                     {produto.produto}
                  </option>
               ))}
            </select>
            <div>
               <h4 className='mt-4 text-lg font-bold'>Adicionar Componente</h4>
               <label
                  htmlFor='SKU'
                  className='block text-sm font-medium text-gray-700'
               >
                  SKU
               </label>
               <input
                  type='text'
                  name='SKU'
                  id='SKU'
                  value={componente.SKU}
                  onChange={handleComponenteChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
               <label
                  htmlFor='Valor'
                  className='block text-sm font-medium text-gray-700'
               >
                  Valor
               </label>
               <input
                  type='number'
                  name='Valor'
                  id='Valor'
                  value={componente.Valor}
                  onChange={handleComponenteChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
               <button
                  type='button'
                  onClick={addComponente}
                  className='button mt-2 px-4 py-2'
               >
                  Adicionar Componente
               </button>
            </div>
            <div className='mt-4'>
               <h5 className='text-md font-bold'>Componentes Adicionados</h5>
               <ul className='table-auto border'>
                  {formData.Componentes.map((comp, index) => {
                     const valor = String(comp.Valor)
                        .replace(/^0+/, '')
                        .replace('.', ',')
                     return (
                        <li
                           key={index}
                           className='mt-1 flex items-center justify-between border'
                        >
                           <span>
                              {comp.SKU}: R$ {valor}
                           </span>
                           <button
                              className='ml-4 flex items-center rounded bg-red-500 p-2 text-white'
                              onClick={() => removeComponente(index)}
                           >
                              <FaTrashAlt />
                           </button>
                        </li>
                     )
                  })}
               </ul>
            </div>
            <label
               htmlFor='Preco'
               className='block hidden text-sm font-medium text-gray-700'
            >
               Preço
            </label>
            <input
               type='text'
               disabled
               name='Preco'
               id='Preco'
               value={formData.Preco}
               onChange={handleChange}
               className='mt-1 block hidden w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            />
            <div className='w-full'>
               <label
                  htmlFor='PesoSKU'
                  className='block text-sm font-medium text-gray-700'
               >
                  Peso SKU
               </label>
               <input
                  type='text'
                  name='PesoSKU'
                  id='PesoSKU'
                  value={item.PesoSKU}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='NVV'
                  className='block text-sm font-medium text-gray-700'
               >
                  NVV (%)
               </label>
               <input
                  type='text'
                  name='NVV'
                  id='NVV'
                  value={formData.NVV}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PesoKit'
                  className='block text-sm font-medium text-gray-700'
               >
                  Peso kit (kg)
               </label>
               <input
                  type='text'
                  name='PesoKit'
                  id='PesoKit'
                  onChange={handleChange}
                  value={formData.PesoKit}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PesoEspecifico'
                  className='block text-sm font-medium text-gray-700'
               >
                  Peso específico (g/cm³) *
               </label>
               <input
                  type='text'
                  name='PesoEspecifico'
                  id='PesoEspecifico'
                  value={formData.PesoEspecifico}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='VolumeKit'
                  className='block text-sm font-medium text-gray-700'
               >
                  Volume Kit (ℓ)
               </label>
               <input
                  type='text'
                  name='VolumeKit'
                  id='VolumeKit'
                  value={item.VolumeKit}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='EPS'
                  className='block text-sm font-medium text-gray-700'
               >
                  EPS (µm) *
               </label>
               <input
                  required
                  type='text'
                  name='EPS'
                  id='EPS'
                  value={formData.EPS}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='h'
                  className='block text-sm font-medium text-gray-700'
               >
                  h (cm) *
               </label>
               <input
                  required
                  type='text'
                  name='h'
                  id='h'
                  value={formData.h}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='R'
                  className='block text-sm font-medium text-gray-700'
               >
                  R (cm) *
               </label>
               <input
                  required
                  type='text'
                  name='R'
                  id='R'
                  value={formData.R}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='mLinear'
                  className='block text-sm font-medium text-gray-700'
               >
                  mLinear *
               </label>
               <input
                  required
                  type='text'
                  name='mLinear'
                  id='mLinear'
                  value={formData.mLinear}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='Consumo'
                  className='block text-sm font-medium text-gray-700'
               >
                  Consumo (kg/mLinear)
               </label>
               <input
                  type='text'
                  name='Consumo'
                  id='Consumo'
                  value={item.Consumo}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='RendTeoricoL'
                  className='block text-sm font-medium text-gray-700'
               >
                  Rend. Teórico (mLinear/ℓ)
               </label>
               <input
                  type='text'
                  name='RendTeoricoL'
                  id='RendTeoricoL'
                  value={item.RendTeoricoL}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='RendTeoricoK'
                  className='block text-sm font-medium text-gray-700'
               >
                  Rend. Teórico (mLinear/kit)
               </label>
               <input
                  type='text'
                  name='RendTeoricoK'
                  id='RendTeoricoK'
                  value={item.RendTeoricoK}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='Kg'
                  className='block text-sm font-medium text-gray-700'
               >
                  Kg
               </label>
               <input
                  type='text'
                  name='Kg'
                  id='Kg'
                  value={item.Kg}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='UnidadesOuKits'
                  className='block text-sm font-medium text-gray-700'
               >
                  Unidades ou kits
               </label>
               <input
                  type='text'
                  name='UnidadesOuKits'
                  id='UnidadesOuKits'
                  value={item.UnidadesOuKits}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PrecoKg'
                  className='block text-sm font-medium text-gray-700'
               >
                  Preço (R$/kg)
               </label>
               <input
                  type='text'
                  name='PrecoKg'
                  id='PrecoKg'
                  value={item.PrecoKg}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PrecoML'
                  className='block text-sm font-medium text-gray-700'
               >
                  Preço (R$/mLinear)
               </label>
               <input
                  type='text'
                  name='PrecoML'
                  id='PrecoML'
                  value={item.PrecoML}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PrecoL'
                  className='block text-sm font-medium text-gray-700'
               >
                  Preço (R$/ℓ)
               </label>
               <input
                  type='text'
                  name='PrecoL'
                  id='PrecoL'
                  value={item.PrecoL}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='w-full'>
               <label
                  htmlFor='PrecoKit'
                  className='block text-sm font-medium text-gray-700'
               >
                  Preço (R$/kit)
               </label>
               <input
                  type='text'
                  name='PrecoKit'
                  id='PrecoKit'
                  value={produto.PrecoKit}
                  disabled
                  className='disabled mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='flex-btn'>
               <button type='submit' className='button px-8 py-4'>
                  Enviar
               </button>
            </div>
         </form>
         <Footer />
      </div>
   )
}

export default CotacaoCoveForm

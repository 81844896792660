import React from 'react'

interface LogoIISVGProps {
   width?: number
   height?: number
   fill?: string
}

export const LogoIISVG: React.FC<LogoIISVGProps> = ({
   width,
   height,
   fill
}) => {
   console.log(width)
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width={width}
         height={height}
         viewBox={`0 0 700 120`}
      >
         <rect width='700' height='200' fill='#005cbf' />
         <text
            x='50'
            y='100'
            fill='white'
            fontFamily='Arial'
            fontSize='60'
            fontWeight='bold'
         >
            SHERWIN
         </text>
         <text
            x='50'
            y='160'
            fill='white'
            fontFamily='Arial'
            fontSize='60'
            fontWeight='bold'
         >
            WILLIAMS
         </text>
         <line
            x1='350'
            y1='40'
            x2='350'
            y2='160'
            stroke='white'
            strokeWidth='2'
         />
         <text
            x='380'
            y='60'
            fill='white'
            fontFamily='Arial'
            fontSize='30'
            fontWeight='normal'
         >
            HIGH
         </text>
         <text
            x='380'
            y='100'
            fill='white'
            fontFamily='Arial'
            fontSize='30'
            fontWeight='normal'
         >
            PERFORMANCE
         </text>
         <text
            x='380'
            y='140'
            fill='white'
            fontFamily='Arial'
            fontSize='30'
            fontWeight='normal'
         >
            FLOORING
         </text>
      </svg>
   )
}

export default LogoIISVG

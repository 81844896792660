import { Routes, Route } from 'react-router-dom'
import Login from '../pages/login'
import Home from '../pages/home'
import CadastroProdutos from '../pages/admin/CadastroProdutos'
import { PrivateRoute } from './PrivateRouter'
import { Logout } from '../pages/logout'
import Produto from '../pages/produto/produto'
import CadastroUsuarios from '../pages/admin/CadastroUsuarios'
import CadastroClientes from '../pages/admin/CadastroClientes'
import CotacaoCove from '../pages/cotacao/cotacaoCove'
import CadastroPreco from '../pages/admin/CadastroPreco'
import CadastroKits from '../pages/admin/CadastroKits'
import CadastroOrcamento from '../pages/cadastroOrcamentos'
import ImpressaoOrcamento from '../components/Orcamento/ImpressaoOrcamento'

const RoutesApp: React.FC = () => {
   return (
      <Routes>
         <Route path='/' element={<PrivateRoute component={<Home />} />} />
         <Route path='/home' element={<PrivateRoute component={<Home />} />} />
         <Route path='/login' element={<Login />} />
         <Route
            path='/admin/cadastro-produtos'
            element={<PrivateRoute component={<CadastroProdutos />} />}
         />
         <Route
            path='/admin/cadastro-usuarios'
            element={<PrivateRoute component={<CadastroUsuarios />} />}
         />
         <Route
            path='/admin/cadastro-clientes'
            element={<PrivateRoute component={<CadastroClientes />} />}
         />
         <Route
            path='/admin/cadastro-preco'
            element={<PrivateRoute component={<CadastroPreco />} />}
         />
         <Route
            path='/admin/cadastro-kits'
            element={<PrivateRoute component={<CadastroKits />} />}
         />
         <Route
            path='/cadastro-orcamento'
            element={<PrivateRoute component={<CadastroOrcamento />} />}
         />
         <Route
            path='/impressao-orcamento'
            element={<PrivateRoute component={<ImpressaoOrcamento />} />}
         />
         <Route
            path='/logout'
            element={<PrivateRoute component={<Logout />} />}
         />
         <Route
            path='/piso'
            element={<PrivateRoute component={<Produto />} />}
         />
         <Route
            path='/rodape'
            element={<PrivateRoute component={<CotacaoCove />} />}
         />
      </Routes>
   )
}

export default RoutesApp

import api, { token } from '../auth/api'

export const getAgent = (id: number) => {
   return api
      .get('/agent/' + id, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os agent:', error)
         throw error
      })
}

export const getAgentById = (id: number) => {
   return api
      .get('/agent/' + id, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os agent:', error)
         throw error
      })
}

export const saveAgent = (data: any) => {
   return api
      .post('/agent/', data, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os agent:', error)
         throw error
      })
}

import React, { useEffect, useState } from 'react'
import { IProduct } from '../../interfaces/IProduct'
import { getProduto } from '../../services/produtos/produtosService'
import {
   calculoCKG,
   calculoMASCAB,
   calculoNVMABC,
   calculoNVVABC,
   calculoPEABC,
   calculoPECAB,
   calculoPKCAT,
   calculoPKR,
   calculoQKGA,
   calculoQKGAGREGADO,
   calculoQKGB,
   calculoQTDAGREGADOLT,
   calculoQUAGREGADO,
   calculoQUCA,
   calculoQUCB,
   calculoVOLCAB
} from '../../util/calculoFunctions'
import { saveCalculo } from '../../services/cotacao/cotacaoService'
import Footer from '../Footer/footer'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import { FaTrashAlt } from 'react-icons/fa'

interface IFormData {
   espessura: string
   quantidade: string
   area: string
   Componentes: { SKU: string; Valor: number }[]
}

interface Iprops {
   produtos: IProduct[]
}

const Formulario = (props: Iprops) => {
   const navigate = useNavigate()
   const { produtos } = props
   const [produto, setProduto] = useState<any>({})
   const [badGet, setBadGet] = useState<any>({})
   const [componente, setComponente] = useState({ SKU: '', Valor: 0 })
   const [formData, setFormData] = useState<IFormData>({
      espessura: '',
      quantidade: '',
      area: '',
      Componentes: []
   })
   const [calculo, setCalculo] = useState({
      CKG: 0,
      QTDAGREGADOLT: 0,
      PECAB: 0,
      MASCAB: 0,
      VOLCAB: 0,
      PEABC: 0,
      NVMABC: 0,
      NVVABC: 0,
      PKR: 0,
      PKCAT: 0,
      QUCA: 0,
      QUCB: 0,
      QUAGREGADO: 0,
      QKGA: 0,
      QKGB: 0,
      QKGAGREGADO: 0
   })

   useEffect(() => {
      if (produto) {
         setProduto((prevState: any) => ({
            ...prevState,
            espessura: formData.espessura,
            quantidade: formData.quantidade,
            area: formData.area
         }))
      }
   }, [formData])

   useEffect(() => {
      if (
         formData.espessura !== '' &&
         formData.quantidade !== '' &&
         formData.area !== ''
      ) {
         const newCalculo = {
            CKG: calculoCKG(produto),
            QTDAGREGADOLT: calculoQTDAGREGADOLT(produto),
            PECAB: calculoPECAB(produto),
            MASCAB: calculoMASCAB(produto),
            VOLCAB: calculoVOLCAB(produto),
            PEABC: calculoPEABC(produto),
            NVMABC: calculoNVMABC(produto),
            NVVABC: calculoNVVABC(produto),
            PKR: calculoPKR(produto),
            PKCAT: calculoPKCAT(produto),
            QUCA: calculoQUCA(produto),
            QUCB: calculoQUCB(produto),
            QUAGREGADO: calculoQUAGREGADO(produto),
            QKGA: calculoQKGA(produto),
            QKGB: calculoQKGB(produto),
            QKGAGREGADO: calculoQKGAGREGADO(produto)
         }
         setCalculo(newCalculo)
      }
   }, [produto])

   const getProduct = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      if (e.target.value === '') {
         return null
      }
      const fetchProdutos = async () => {
         try {
            const response = await getProduto(Number(e.target.value))
            setBadGet({
               ...badGet,
               productId: response.id
            })
            setProduto(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         }
      }
      fetchProdutos()
   }

   const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      setFormData({
         ...formData,
         [e.target.name]: e.target.value
      })
   }

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const newBadGet = {
         ...badGet,
         productId: produto.id,
         calculo: calculo,
         espessura: formData.espessura,
         quantidade: formData.quantidade,
         area: formData.area,
         SKU: groupSKU(formData.Componentes)
      }
      console.log(newBadGet)
      await saveCalculo(newBadGet)
      // navigate('/cotacao-cove', {
      //    state: { dados: newBadGet }
      // })
   }

   const handleComponenteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target
      setComponente(prevComponente => ({
         ...prevComponente,
         [name]: value
      }))
   }

   const sumComponents = (components: { SKU: string; Valor: number }[]) => {
      return components.reduce((total, item) => total + Number(item.Valor), 0)
   }

   const groupSKU = (components: { SKU: string; Valor: number }[]) => {
      return components.reduce((grouped, item) => {
         const formattedValue = String(item.Valor)
            .replace(/^0+/, '')
            .replace(',', '.')
         const skuValueString = `${item.SKU}: ${formattedValue}`
         if (!grouped.includes(skuValueString)) {
            grouped.push(skuValueString)
         }
         return grouped
      }, [] as string[])
   }

   const addComponente = () => {
      if (componente.SKU.trim() === '' || componente.Valor <= 0) {
         toast.error('SKU e Valor não podem estar vazios ou ser iguais a 0.', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
         })
         return
      }

      setFormData((prevFormData: any) => {
         const updatedComponents = [...prevFormData.Componentes, componente]
         const updatedPreco = sumComponents(updatedComponents)
         return {
            ...prevFormData,
            Componentes: updatedComponents,
            Preco: updatedPreco
         }
      })
      setComponente({ SKU: '', Valor: 0 })
   }

   const removeComponente = (index: number) => {
      setFormData((prevFormData: any) => {
         const updatedComponents = prevFormData.Componentes.filter(
            (_: any, i: number) => i !== index
         )
         const updatedPreco = sumComponents(updatedComponents)
         return {
            ...prevFormData,
            Componentes: updatedComponents,
            Preco: updatedPreco
         }
      })
   }

   return (
      <div className='form-container'>
         <ToastContainer />
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            <h3 className='text-center text-lg font-bold'>
               Dados do produto - {produto.produto}
            </h3>
            <label
               htmlFor='description'
               className='block text-sm font-medium text-gray-700'
            >
               Produto
            </label>
            <select
               name='description'
               id='productId'
               value={produto.id}
               onChange={e => getProduct(e)}
               className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            >
               <option value=''>Selecione um produto</option>
               {produtos.map((produto: IProduct) => (
                  <option key={produto.id} value={produto.id}>
                     {produto.produto}
                  </option>
               ))}
            </select>
            <div>
               <h4 className='mt-4 text-lg font-bold'>Adicionar Componente</h4>
               <label
                  htmlFor='SKU'
                  className='block text-sm font-medium text-gray-700'
               >
                  SKU
               </label>
               <input
                  type='text'
                  name='SKU'
                  id='SKU'
                  value={componente.SKU}
                  onChange={handleComponenteChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
               <label
                  htmlFor='Valor'
                  className='block text-sm font-medium text-gray-700'
               >
                  Valor
               </label>
               <input
                  type='number'
                  name='Valor'
                  id='Valor'
                  value={componente.Valor}
                  onChange={handleComponenteChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
               <button
                  type='button'
                  onClick={addComponente}
                  className='button mt-2 px-4 py-2'
               >
                  Adicionar Componente
               </button>
            </div>
            <div className='mt-4'>
               <h5 className='text-md font-bold'>Componentes Adicionados</h5>
               <ul className='table-auto border'>
                  {formData.Componentes.map((comp, index) => {
                     const valor = String(comp.Valor)
                        .replace(/^0+/, '')
                        .replace('.', ',')
                     return (
                        <li
                           key={index}
                           className='mt-1 flex items-center justify-between border'
                        >
                           <span>
                              {comp.SKU}: R$ {valor}
                           </span>
                           <button
                              className='ml-4 flex items-center rounded bg-red-500 p-2 text-white'
                              onClick={() => removeComponente(index)}
                           >
                              <FaTrashAlt />
                           </button>
                        </li>
                     )
                  })}
               </ul>
            </div>
            <div className='mx-3 mb-6 flex flex-wrap'>
               <div className='hidden'>
                  <label
                     htmlFor='CPA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Código A
                  </label>
                  <input
                     type='text'
                     name='CPA'
                     id='CPA'
                     disabled
                     value={produto.CPA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='CPB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Código B
                  </label>
                  <input
                     type='text'
                     name='CPB'
                     id='CPB'
                     disabled
                     value={produto.CPB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='TA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Tipo de Aplicação
                  </label>
                  <input
                     type='text'
                     name='TA'
                     id='TA'
                     disabled
                     value={produto.TA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='MIX'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Agregado
                  </label>
                  <input
                     type='text'
                     name='MIX'
                     id='MIX'
                     disabled
                     value={produto.MIX}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='VAVB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Relação ao Volume A
                  </label>
                  <input
                     type='text'
                     name='VAVB'
                     id='VAVB'
                     disabled
                     value={produto.VAVB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PENVDESC'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Relação ao Volume Produto
                  </label>
                  <input
                     type='text'
                     name='PENVDESC'
                     id='PENVDESC'
                     disabled
                     value={produto.PENVDESC}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PENVMIX'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Relação ao Volume Mix
                  </label>
                  <input
                     type='text'
                     name='PENVMIX'
                     id='PENVMIX'
                     disabled
                     value={produto.PENVMIX}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PESO'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso Saco agregado
                  </label>
                  <input
                     type='text'
                     name='PESO'
                     id='PESO'
                     disabled
                     value={produto.PSA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PEAMIX'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso Especifico Agregado
                  </label>
                  <input
                     type='text'
                     name='PEAMIX'
                     id='PEAMIX'
                     disabled
                     value={produto.PEAMIX}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PEA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso Especifico A
                  </label>
                  <input
                     type='text'
                     name='PEA'
                     id='PEA'
                     disabled
                     value={produto.PEA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PEB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso Especifico B
                  </label>
                  <input
                     type='text'
                     name='PEB'
                     id='PEB'
                     disabled
                     value={produto.PEB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='PEAB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso Especifico A+B
                  </label>
                  <input
                     type='text'
                     name='PEAB'
                     id='PEAB'
                     disabled
                     value={produto.PEAB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVMA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVM A
                  </label>
                  <input
                     type='text'
                     name='NVMA'
                     id='NVMA'
                     disabled
                     value={produto.NVMA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVMB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVM B
                  </label>
                  <input
                     type='text'
                     name='NVMB'
                     id='NVMB'
                     disabled
                     value={produto.NVMB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVMMIX'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVM MIX
                  </label>
                  <input
                     type='text'
                     name='NVMMIX'
                     id='NVMMIX'
                     disabled
                     value={produto.NVMMIX}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVVA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVV A
                  </label>
                  <input
                     type='text'
                     name='NVVA'
                     id='NVVA'
                     disabled
                     value={produto.NVVA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVVB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVV B
                  </label>
                  <input
                     type='text'
                     name='NVVB'
                     id='NVVB'
                     disabled
                     value={produto.NVVB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='NVVMIX'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVV MIX
                  </label>
                  <input
                     type='text'
                     name='NVVMIX'
                     id='NVVMIX'
                     disabled
                     value={produto.NVVMIX}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <hr className='hidden' />
            <h3 className='text-center text-lg font-bold'>
               Entrada de usuario
            </h3>
            <div className='flex-mobile mx-3 mb-6 flex flex-wrap'>
               <div className='ml-1'>
                  <label
                     htmlFor='area'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Area, m²
                  </label>
                  <input
                     required
                     type='text'
                     name='area'
                     id='area'
                     value={formData.area}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='espessura'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Espessura, µm
                  </label>
                  <input
                     required
                     type='text'
                     name='espessura'
                     id='espessura'
                     value={formData.espessura}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='quantidade'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Quantidade agregado por kit, Kg
                  </label>
                  <input
                     required
                     type='text'
                     name='quantidade'
                     id='quantidade'
                     value={formData.quantidade}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <hr className='hidden' />
            <div className='mx-3 mb-6 flex hidden flex-wrap'>
               <div className='ml-1 '>
                  <label
                     htmlFor='CKG'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Consumo, Kg/m²
                  </label>
                  <input
                     type='text'
                     name='CKG'
                     id='CKG'
                     disabled
                     value={calculo.CKG}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1'>
                  <label
                     htmlFor='QTDAGREGADOLT'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Quantidade agregado por kit,Lt
                  </label>
                  <input
                     type='text'
                     name='QTDAGREGADOLT'
                     id='QTDAGREGADOLT'
                     disabled
                     value={calculo.QTDAGREGADOLT}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1'>
                  <label
                     htmlFor='PECAB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Pe C/(A+B)
                  </label>
                  <input
                     type='text'
                     name='PECAB'
                     id='PECAB'
                     disabled
                     value={calculo.PECAB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='MASCAB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Mas C/(A+B)
                  </label>
                  <input
                     type='text'
                     name='MASCAB'
                     id='MASCAB'
                     disabled
                     value={calculo.MASCAB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='VOLCAB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Vol C/(A+B)
                  </label>
                  <input
                     type='text'
                     name='VOLCAB'
                     id='VOLCAB'
                     disabled
                     value={calculo.VOLCAB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='PEABC'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Peso especifico (A+B+C)
                  </label>
                  <input
                     type='text'
                     name='PEABC'
                     id='PEABC'
                     disabled
                     value={calculo.PEABC}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='NVMABC'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVM (A+B+C)
                  </label>
                  <input
                     type='text'
                     name='NVMABC'
                     id='NVMABC'
                     disabled
                     value={calculo.NVMABC}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='NVVABC'
                     className='block text-sm font-medium text-gray-700'
                  >
                     NVV (A+B+C)
                  </label>
                  <input
                     type='text'
                     name='NVVABC'
                     id='NVVABC'
                     disabled
                     value={calculo.NVVABC}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <hr className='hidden' />
            <h3 className='hidden text-center text-lg font-bold'>Por Kit</h3>
            <div className='mx-3 mb-6 flex hidden flex-wrap'>
               <div className='ml-1 '>
                  <label
                     htmlFor='PKR'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Rendimento, m²
                  </label>
                  <input
                     type='text'
                     name='PKR'
                     id='PKR'
                     disabled
                     value={calculo.PKR}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 '>
                  <label
                     htmlFor='PKCAT'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Consumo / área total
                  </label>
                  <input
                     type='text'
                     name='PKCAT'
                     id='PKCAT'
                     disabled
                     value={calculo.PKCAT}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <hr className='hidden' />
            <h3 className='hidden text-center text-lg font-bold'>
               Quantidade - Unidades
            </h3>
            <div className='mx-3 mb-6 flex hidden flex-wrap'>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QUCA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Comp. A
                  </label>
                  <input
                     type='text'
                     name='QUCA'
                     id='QUCA'
                     disabled
                     value={calculo.QUCA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QUCB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Comp. B
                  </label>
                  <input
                     type='text'
                     name='QUCB'
                     id='QUCB'
                     disabled
                     value={calculo.QUCB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QUAGREGADO'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Agregado
                  </label>
                  <input
                     type='text'
                     name='QUAGREGADO'
                     id='QUAGREGADO'
                     disabled
                     value={calculo.QUAGREGADO}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <hr className='hidden' />
            <h3 className='hidden text-center text-lg font-bold'>
               Quantidade - Quilos
            </h3>
            <div className='mx-3 mb-6 flex hidden flex-wrap'>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QKGA'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Comp. A
                  </label>
                  <input
                     type='text'
                     name='QKGA'
                     id='QKGA'
                     disabled
                     value={calculo.QKGA}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QKGB'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Comp. B
                  </label>
                  <input
                     type='text'
                     name='QKGB'
                     id='QKGB'
                     disabled
                     value={calculo.QKGB}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
               <div className='ml-1 hidden'>
                  <label
                     htmlFor='QKGAGREGADO'
                     className='block text-sm font-medium text-gray-700'
                  >
                     Agregado
                  </label>
                  <input
                     type='text'
                     name='QKGAGREGADO'
                     id='QKGAGREGADO'
                     disabled
                     value={calculo.QKGAGREGADO}
                     onChange={handleInputChange}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  />
               </div>
            </div>
            <button type='submit' className='button px-8 py-4'>
               Próximo
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default Formulario

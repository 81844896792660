export const obterClientes = async () => {
   const response = await fetch('/api/clientes')
   return response.json()
}

export const obterProdutos = async () => {
   const response = await fetch('/api/produtos')
   return response.json()
}

export const salvarOrcamento = async (formData: any) => {
   await fetch('/api/orcamentos', {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
   })
}

import React from 'react'
import LogoSVG from '../Layouts/svg/Logo'
import LogoIISVG from '../Layouts/svg/LogoII'

const Header: React.FC<{ dados: any }> = ({ dados }) => {
   return (
      <div>
         <div className='mx-2 mb-4 flex items-center justify-between'>
            <LogoSVG width={300} height={120} />
            <LogoIISVG width={300} height={120} />
         </div>
         <div className='mx-2 mb-4'>
            <h1 className='titulo flex-grow border border-gray-300 text-center text-xl font-bold'>
               PROPOSTA COMERCIAL - REVESTIMENTOS PARA PISOS II
            </h1>
            <div className='grid grid-cols-12'>
               <div className='col-span-5 border'>
                  <h2 className='tituloBlue bg-blue-800 py-1 text-center text-lg font-semibold text-white'>
                     DADOS DO CLIENTE
                  </h2>
                  <div className='grid grid-cols-2 gap-4'>
                     <div>
                        <p>
                           <span className='font-semibold'>CLIENTE:</span>{' '}
                           {dados.cliente.nome}
                        </p>
                        <p>
                           <span className='font-semibold'>CONTATO:</span>{' '}
                           {dados.cliente.contato}
                        </p>
                        <p>
                           <span className='font-semibold'>E-MAIL:</span>{' '}
                           {dados.cliente.email}
                        </p>
                        <p>
                           <span className='font-semibold'>CIDADE:</span>{' '}
                           {dados.cliente.cidade}
                        </p>
                        <p>
                           <span className='font-semibold'>BAIRRO:</span>{' '}
                           {dados.cliente.bairro}
                        </p>
                     </div>
                     <div>
                        <p>
                           <span className='font-semibold'>
                              CNPJ - CÓDIGO DO CLIENTE:
                           </span>{' '}
                           {dados.cliente.cnpj}
                        </p>
                        <p>
                           <span className='font-semibold'>FONE/FAX:</span>{' '}
                           {dados.cliente.fone}
                        </p>
                        <p>
                           <span className='font-semibold'>CEP:</span>{' '}
                           {dados.cliente.cep}
                        </p>
                        <p>
                           <span className='font-semibold'>UF:</span> PE
                        </p>
                     </div>
                  </div>
                  <p>
                     <span className='font-semibold'>OBRA:</span>{' '}
                     {dados.cliente.obra}
                  </p>
               </div>

               <div className='col-span-5 border'>
                  <h2 className='tituloBlue bg-blue-800 py-1 text-center text-lg font-semibold text-white'>
                     DADOS SHERWIN WILLIAMS
                  </h2>
                  <div className='grid grid-cols-2 gap-4'>
                     <div>
                        <p>
                           <span className='font-semibold'>
                              LOJA SHERWIN WILLIAMS:
                           </span>{' '}
                           {dados.sherwinWilliams.loja}
                        </p>
                        <p>
                           <span className='font-semibold'>CONTATO:</span>{' '}
                           {dados.sherwinWilliams.contato}
                        </p>
                        <p>
                           <span className='font-semibold'>EMAIL:</span>{' '}
                           {dados.sherwinWilliams.email}
                        </p>
                     </div>
                     <div>
                        <p>
                           <span className='font-semibold'>CNPJ:</span>{' '}
                           {dados.sherwinWilliams.cnpj}
                        </p>
                        <p>
                           <span className='font-semibold'>FONE:</span>{' '}
                           {dados.sherwinWilliams.fone}
                        </p>
                        <p>
                           <span className='font-semibold'>ENDEREÇO:</span>{' '}
                           {dados.sherwinWilliams.endereco}
                        </p>
                     </div>
                  </div>
               </div>

               <div className='col-span-2 grid grid-rows-4 gap-5 border text-center'>
                  <div className='bg-blue-800 py-1 text-white'>
                     <span className='font-semibold'>ORÇAMENTO NRO:</span>
                  </div>
                  <p className='font-semibold'>{dados.orcamento.numero}</p>
                  <div className='mt-2 bg-blue-800 py-1 text-white'>
                     <span className='font-semibold'>DATA DE EMISSÃO:</span>
                  </div>
                  <p className='font-semibold'>{dados.orcamento.dataEmissao}</p>
               </div>
            </div>
         </div>
      </div>
   )
}

export default Header

import React, { useState, useEffect } from 'react'
import Cliente from './Cliente'
import Produto from './Produto'
import { salvarOrcamento } from '../../services/orcamento/index'
import { getClient } from '../../services/clients'
import { getProdutos } from '../../services/produtos/produtosService'
import { FaPlus } from 'react-icons/fa'

const Orcamento: React.FC = () => {
   const [cliente, setCliente] = useState<string | null>(null)
   const [produtos, setProdutos] = useState<
      { id: string; Description: string; quantidade: number }[]
   >([])
   const [todosClientes, setTodosClientes] = useState<any[]>([])
   const [todosProdutos, setTodosProdutos] = useState<any[]>([])

   useEffect(() => {
      getClient().then(setTodosClientes)
      getProdutos().then(setTodosProdutos)
   }, [])

   const adicionarProduto = () => {
      setProdutos(prev => [...prev, { id: '', Description: '', quantidade: 1 }])
   }

   const atualizarProduto = (
      index: number,
      produtoId: string,
      Description: string
   ) => {
      setProdutos(prev => {
         const novosProdutos = [...prev]
         novosProdutos[index] = {
            ...novosProdutos[index],
            id: produtoId,
            Description
         }
         return novosProdutos
      })
   }

   const removerProduto = (index: number) => {
      setProdutos(prev => prev.filter((_, i) => i !== index))
   }

   const atualizarQuantidade = (index: number, quantidade: number) => {
      setProdutos(prev => {
         const novosProdutos = [...prev]
         novosProdutos[index] = { ...novosProdutos[index], quantidade }
         return novosProdutos
      })
   }

   const handleSalvar = async () => {
      if (cliente && produtos.length > 0) {
         const formData = { cliente, produtos }
         console.log(formData)
         await salvarOrcamento(formData)
      }
   }

   return (
      <div className='form-container mx-auto p-4 text-center'>
         <h1 className='mb-4 text-2xl font-bold'>Orçamento</h1>
         <Cliente
            cliente={cliente}
            setCliente={setCliente}
            todosClientes={todosClientes}
         />
         <div className='mt-4'>
            <h2 className='text-xl font-semibold'>Produtos</h2>
            {produtos.map((produto, index) => (
               <Produto
                  key={index + 1}
                  index={index}
                  produto={produto}
                  todosProdutos={todosProdutos}
                  removerProduto={removerProduto}
                  atualizarProduto={atualizarProduto}
                  atualizarQuantidade={atualizarQuantidade}
               />
            ))}
            <button
               className='mt-2 flex items-center rounded bg-blue-500 p-2 text-white'
               onClick={adicionarProduto}
            >
               <FaPlus className='mr-2' /> Adicionar Produto
            </button>
         </div>
         <button
            className='mt-4 rounded bg-green-500 p-2 text-white'
            onClick={handleSalvar}
         >
            Salvar Orçamento
         </button>
      </div>
   )
}

export default Orcamento

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/fundo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 600px) {
   body {
      background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
   }
   .flex-mobile {
      flex-direction: column;
   }
   .form-container {
      width: 90%;
      margin: 0;
   }
}
`, "",{"version":3,"sources":["webpack://./src/styles/mobile.css"],"names":[],"mappings":"AAAA;GACG;MACG,oEAAuD;MACvD,wBAAwB;MACxB,4BAA4B;MAC5B,2BAA2B;MAC3B,4BAA4B;GAC/B;GACA;MACG,sBAAsB;GACzB;GACA;MACG,UAAU;MACV,SAAS;GACZ;AACH","sourcesContent":["@media only screen and (max-width: 600px) {\r\n   body {\r\n      background-image: url('../images/fundo.png') !important;\r\n      background-size: contain;\r\n      background-repeat: no-repeat;\r\n      background-position: center;\r\n      background-attachment: fixed;\r\n   }\r\n   .flex-mobile {\r\n      flex-direction: column;\r\n   }\r\n   .form-container {\r\n      width: 90%;\r\n      margin: 0;\r\n   }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from 'react'
import { IUser } from '../../interfaces/IUser'
import Footer from '../Footer/footer'
import { saveAgent } from '../../services/agents/agents'
import { useNavigate } from 'react-router-dom'

const UserForm: React.FC = () => {
   const [agent, setAgent] = useState<IUser>({
      full_name: '',
      cpf: '',
      phone_number: '',
      email: '',
      permission: 'usuario',
      activated: true
   })
   const navigate = useNavigate()

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      const { name, value } = e.target
      setAgent({ ...agent, [name]: value })
   }

   const handleCpfChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '')
      value = value.slice(0, 11) // Limita o tamanho a 11 dígitos
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d)/, '$1.$2')
      value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      setAgent({ ...agent, cpf: value })
   }

   const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '')
      if (value.length <= 10) {
         // Formatação para números de telefone com 10 dígitos (fixo)
         value = value.slice(0, 10) // Limita o tamanho a 11 dígitos
         value = value.replace(/(\d{2})(\d)/, '($1) $2')
         value = value.replace(/(\d{4})(\d{4})$/, '$1-$2')
      } else {
         value = value.slice(0, 11) // Limita o tamanho a 11 dígitos
         value = value.replace(/(\d{2})(\d)/, '($1) $2')
         value = value.replace(/(\d{5})(\d{4})$/, '$1-$2')
      }
      setAgent({ ...agent, phone_number: value })
   }

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      await saveAgent(agent).then(() => navigate('/home'))
   }

   return (
      <div className='form-container'>
         <h1>Cadastro de Usuários</h1>
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            <div className='mb-6 grid gap-6 md:grid-cols-2'>
               <div>
                  <label
                     htmlFor='full_name'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Nome Completo *
                  </label>
                  <input
                     type='text'
                     name='full_name'
                     id='full_name'
                     value={agent.full_name}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='document'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     C.P.F. *
                  </label>
                  <input
                     type='text'
                     name='document'
                     id='document'
                     value={agent.cpf}
                     onChange={handleCpfChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='phone_number'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Telefone *
                  </label>
                  <input
                     type='text'
                     name='phone_number'
                     id='phone_number'
                     value={agent.phone_number}
                     onChange={handlePhoneChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='email'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Email *
                  </label>
                  <input
                     type='email'
                     name='email'
                     id='email'
                     value={agent.email}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='permission'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Permissão *
                  </label>
                  <select
                     name='permission'
                     id='permission'
                     value={agent.permission}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  >
                     <option value='admin'>Admin</option>
                     <option value='usuario'>Usuário</option>
                  </select>
               </div>
               <div>
                  <label
                     htmlFor='activated'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Ativado *
                  </label>
                  <select
                     name='activated'
                     id='activated'
                     value={agent.activated ? 'true' : 'false'}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  >
                     <option value='true'>Sim</option>
                     <option value='false'>Não</option>
                  </select>
               </div>
            </div>
            <p className='text-sm text-red-500'>
               Campos com * são obrigatórios
            </p>
            <button type='submit' className='button px-8 py-4'>
               Cadastrar Usuário
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default UserForm

import ClienteForm from '../../components/Forms/ClientsForm'
import AdminNavBar from '../../components/NavBar/navBar'

const CadastroClientes: React.FC = () => {
   return (
      <div className='text-center'>
         <AdminNavBar />
         <ClienteForm />
      </div>
   )
}
export default CadastroClientes

import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { ICliente } from '../../interfaces/IClients'
import Footer from '../Footer/footer'
import { saveClient } from '../../services/clients'
import { useNavigate } from 'react-router-dom'

const ClienteForm: React.FC = () => {
   const [cliente, setCliente] = useState<ICliente>({
      full_name: '',
      document: '',
      document_type: 'CPF',
      phone_number: '',
      mobile_number: '',
      email: '',
      cep: '',
      logradouro: '',
      bairro: '',
      localidade: '',
      uf: '',
      numero: '',
      complemento: ''
   })
   const navigate = useNavigate()

   useEffect(() => {
      if (cliente.cep.length === 9) {
         axios
            .get(`https://viacep.com.br/ws/${cliente.cep}/json/`)
            .then(response => {
               if (!response.data.erro) {
                  setCliente(prevCliente => ({
                     ...prevCliente,
                     logradouro: response.data.logradouro,
                     bairro: response.data.bairro,
                     localidade: response.data.localidade,
                     uf: response.data.uf
                  }))
               }
            })
            .catch(error => {
               console.error('Error fetching CEP data:', error)
            })
      }
   }, [cliente.cep])

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      const { name, value } = e.target
      setCliente({ ...cliente, [name]: value })
   }

   const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '') // Remove todos os caracteres não numéricos

      if (cliente.document_type === 'CPF') {
         value = value.slice(0, 11) // Limita o tamanho a 11 dígitos
         value = value.replace(/(\d{3})(\d)/, '$1.$2')
         value = value.replace(/(\d{3})(\d)/, '$1.$2')
         value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2')
      } else {
         value = value.slice(0, 14) // Limita o tamanho a 14 dígitos
         value = value.replace(/(\d{2})(\d)/, '$1.$2')
         value = value.replace(/(\d{3})(\d)/, '$1.$2')
         value = value.replace(/(\d{3})(\d)/, '$1/$2')
         value = value.replace(/(\d{4})(\d{1,2})$/, '$1-$2')
      }

      setCliente({ ...cliente, document: value })
   }

   const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '') // Remove todos os caracteres não numéricos

      if (value.length <= 10) {
         // Formatação para números de telefone com 10 dígitos (fixo)
         value = value.slice(0, 10) // Limita o tamanho a 11 dígitos
         value = value.replace(/(\d{2})(\d)/, '($1) $2')
         value = value.replace(/(\d{4})(\d{4})$/, '$1-$2')
      } else {
         value = value.slice(0, 11) // Limita o tamanho a 11 dígitos
         value = value.replace(/(\d{2})(\d)/, '($1) $2')
         value = value.replace(/(\d{5})(\d{4})$/, '$1-$2')
      }

      setCliente({ ...cliente, phone_number: value })
   }

   const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '') // Remove todos os caracteres não numéricos
      value = value.slice(0, 11) // Limita o tamanho a 11 dígitos
      value = value.replace(/(\d{2})(\d)/, '($1) $2')
      value = value.replace(/(\d{5})(\d{4})$/, '$1-$2')
      setCliente({ ...cliente, mobile_number: value })
   }

   const handleCepChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let value = e.target.value.replace(/\D/g, '') // Remove todos os caracteres não numéricos
      value = value.slice(0, 8) // Limita o tamanho a 8 dígitos
      value = value.replace(/(\d{5})(\d)/, '$1-$2')
      setCliente({ ...cliente, cep: value })
   }

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      await saveClient(cliente).then(() => navigate('/home'))
   }

   return (
      <div className='form-container'>
         <h1>Cadastro de Clientes</h1>
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            <div className='mb-6 grid gap-6 md:grid-cols-2'>
               <div>
                  <label
                     htmlFor='full_name'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Nome Completo *
                  </label>
                  <input
                     type='text'
                     name='full_name'
                     id='full_name'
                     value={cliente.full_name}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='document_type'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Tipo de Documento *
                  </label>
                  <select
                     name='document_type'
                     id='document_type'
                     value={cliente.document_type}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  >
                     <option value='CPF'>CPF</option>
                     <option value='CNPJ'>CNPJ</option>
                  </select>
               </div>
               <div>
                  <label
                     htmlFor='document'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     {cliente.document_type === 'CPF' ? 'CPF' : 'CNPJ'} *
                  </label>
                  <input
                     type='text'
                     name='document'
                     id='document'
                     value={cliente.document}
                     onChange={handleDocumentChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='phone_number'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Telefone *
                  </label>
                  <input
                     type='text'
                     name='phone_number'
                     id='phone_number'
                     value={cliente.phone_number}
                     onChange={handlePhoneChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='mobile_number'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Telefone Celular *
                  </label>
                  <input
                     type='text'
                     name='mobile_number'
                     id='mobile_number'
                     value={cliente.mobile_number}
                     onChange={handleMobileChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='email'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Email *
                  </label>
                  <input
                     type='email'
                     name='email'
                     id='email'
                     value={cliente.email}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               {cliente.document_type === 'CNPJ' && (
                  <>
                     <div>
                        <label
                           htmlFor='contact_name'
                           className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                        >
                           Nome do Responsável
                        </label>
                        <input
                           type='text'
                           name='contact_name'
                           id='contact_name'
                           value={cliente.contact_name}
                           onChange={handleChange}
                           className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                        />
                     </div>
                     <div>
                        <label
                           htmlFor='contact_phone'
                           className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                        >
                           Telefone do Responsável
                        </label>
                        <input
                           type='text'
                           name='contact_phone'
                           id='contact_phone'
                           value={cliente.contact_phone}
                           onChange={handlePhoneChange}
                           className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                        />
                     </div>
                     <div>
                        <label
                           htmlFor='contact_email'
                           className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                        >
                           Email do Responsável
                        </label>
                        <input
                           type='email'
                           name='contact_email'
                           id='contact_email'
                           value={cliente.contact_email}
                           onChange={handleChange}
                           className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                        />
                     </div>
                  </>
               )}
            </div>
            <div className='mb-6 grid gap-6 md:grid-cols-2'>
               <div>
                  <label
                     htmlFor='cep'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     CEP *
                  </label>
                  <input
                     type='text'
                     name='cep'
                     id='cep'
                     value={cliente.cep}
                     onChange={handleCepChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='logradouro'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Logradouro *
                  </label>
                  <input
                     type='text'
                     name='logradouro'
                     id='logradouro'
                     value={cliente.logradouro}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='bairro'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Bairro *
                  </label>
                  <input
                     type='text'
                     name='bairro'
                     id='bairro'
                     value={cliente.bairro}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='localidade'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Cidade *
                  </label>
                  <input
                     type='text'
                     name='localidade'
                     id='localidade'
                     value={cliente.localidade}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='uf'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Estado *
                  </label>
                  <input
                     type='text'
                     name='uf'
                     id='uf'
                     value={cliente.uf}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='numero'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Número *
                  </label>
                  <input
                     type='text'
                     name='numero'
                     id='numero'
                     value={cliente.numero}
                     onChange={handleChange}
                     required={
                        cliente.numero !== '0' && cliente.numero !== 'SN'
                     }
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='complemento'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Complemento
                  </label>
                  <input
                     type='text'
                     name='complemento'
                     id='complemento'
                     value={cliente.complemento}
                     onChange={handleChange}
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
            </div>
            <p className='text-sm text-red-500'>
               Campos com * são obrigatórios
            </p>
            <button type='submit' className='button px-8 py-4'>
               Cadastrar Cliente
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default ClienteForm

import api, { token } from '../auth/api'

export const getProdutos = () => {
   return api
      .get('/products', {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

export const getProduto = (id: number) => {
   return api
      .get('/products/' + id, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

export const getProdutoPrice = (id: number) => {
   return api
      .get('/products/price/' + id, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

export const saveProduto = (data: any) => {
   return api
      .post('/products/product', data, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

export const saveProdutoPrice = (data: any) => {
   return api
      .post('/products/price', data, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

export const saveCotacaoCove = (data: any) => {
   return api
      .post('/products/cotacaoCove', data, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao buscar os produtos:', error)
         throw error
      })
}

import React, { useState } from 'react'
import logo from '../images/logo.svg'
import api from '../services/auth/api'
import { toast, ToastContainer } from 'react-toastify'

const Login = () => {
   const [username, setUsername] = useState('')
   const [message, setMessage] = useState('')
   const [password, setPassword] = useState('')
   const [showPassword, setShowPassword] = useState(false)

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword)
   }

   async function handleSubmit(e: any) {
      e.preventDefault()
      const data = { username, password }
      await api
         .post('/auth', data)
         .then((response: any) => {
            console.log(response)
            sessionStorage.setItem('token', response.data.token)
            sessionStorage.setItem('permission', response.data.permission)
            sessionStorage.setItem(
               'selectedAgent',
               JSON.stringify(response.data)
            )
            window.location.href = '/'
         })
         .catch((err: any) => {
            console.log(err.response.data)
            setMessage(err.response.data.message)
            toast.error(err.response.data.message, {
               position: 'top-right',
               autoClose: 5000,
               hideProgressBar: false,
               closeOnClick: true,
               pauseOnHover: true,
               draggable: true,
               progress: undefined
            })
         })
   }

   return (
      <div className='flex h-screen items-center justify-center bg-cover bg-center'>
         <ToastContainer />
         <div className='form-container'>
            <form
               onSubmit={handleSubmit}
               className='mb-4 rounded bg-white px-8 pb-8 pt-6 shadow-md'
            >
               <div className='mb-4 text-center'>
                  <img src={logo} alt='Logo' className='mx-auto h-16' />
               </div>
               <div className='mb-4'>
                  <label
                     className='mb-2 block text-sm font-bold text-gray-700'
                     htmlFor='username'
                  >
                     Usuário
                  </label>
                  <input
                     className='focus:shadow-outline w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none'
                     id='username'
                     type='text'
                     placeholder='Username'
                     value={username}
                     onChange={e => setUsername(e.target.value)}
                     required
                  />
               </div>
               <div className='mb-6'>
                  <label
                     className='mb-2 block text-sm font-bold text-gray-700'
                     htmlFor='password'
                  >
                     Senha
                  </label>
                  <div className='relative'>
                     <input
                        style={{ paddingLeft: '1rem' }}
                        className='focus:shadow-outline mb-3 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none'
                        id='password'
                        type={showPassword ? 'text' : 'password'}
                        placeholder='******************'
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                     />
                     <a
                        type='button'
                        onClick={togglePasswordVisibility}
                        className='esconder absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5'
                     >
                        {showPassword ? 'Esconder' : 'Mostrar'}
                     </a>
                  </div>
               </div>
               <div className='flex items-center justify-between'>
                  <button
                     className='focus:shadow-outline rounded bg-blue-600 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none'
                     type='submit'
                     style={{ backgroundColor: '#0069AF' }}
                  >
                     Login
                  </button>
                  <a
                     className='inline-block align-baseline text-sm font-bold text-blue-600 hover:text-blue-800'
                     href='#forgot-password'
                  >
                     Esqueceu a Senha?
                  </a>
               </div>
            </form>
         </div>
      </div>
   )
}

export default Login

import React, { useEffect, useState } from 'react'
import NavBar from '../../components/NavBar/navBar'
import CotacaoCoveForm from '../../components/Forms/CotacaoCove'

const CotacaoCove: React.FC = () => {
   const [loading, setLoading] = useState<boolean>(true)

   useEffect(() => {
      setLoading(false)
   }, [])

   return (
      <div className='text-center'>
         <NavBar />
         {loading ? <p>Loading...</p> : <CotacaoCoveForm />}
      </div>
   )
}

export default CotacaoCove

import logo from '../../images/logo.webp'

const Footer = () => {
   return (
      <footer>
         <img src={logo} alt='Logo' className='logo' />
      </footer>
   )
}

export default Footer

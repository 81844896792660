import React, { useEffect, useState } from 'react'
import NavBar from '../../components/NavBar/navBar'
import { getProdutos } from '../../services/produtos/produtosService'
import { IProduct } from '../../interfaces/IProduct'
import Formulario from '../../components/Forms/ProdutosForm'

const Produto: React.FC = () => {
   const [produtos, setProdutos] = useState<IProduct[]>([])
   const [loading, setLoading] = useState<boolean>(true)

   useEffect(() => {
      const fetchProdutos = async () => {
         try {
            const response = await getProdutos()
            setProdutos(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         } finally {
            setLoading(false)
         }
      }
      fetchProdutos()
   }, [])

   return (
      <div className='text-center'>
         <NavBar />
         {loading}
         <Formulario produtos={produtos} />
      </div>
   )
}
export default Produto

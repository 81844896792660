import ProductForm from '../../components/Forms/ProductForm'
import AdminNavBar from '../../components/NavBar/navBar'

const CadastroProdutos: React.FC = () => {
   return (
      <div className='text-center'>
         <AdminNavBar />
         <ProductForm />
      </div>
   )
}
export default CadastroProdutos

import { Menu } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { IMenu } from '../../interfaces/IMenu'

export const MenuAdmin = (props: IMenu) => {
   const { user } = props
   return (
      <Menu as='div' className='relative'>
         <Menu.Button className='rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white hover:bg-gray-600'>
            {user.full_name}
         </Menu.Button>
         <Menu.Items className='absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='p-1'>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/admin/cadastro-usuarios'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Cadastro de Usuários
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/admin/cadastro-clientes'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Cadastro de Clientes
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/admin/cadastro-produtos'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Cadastro de Produtos
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/admin/cadastro-kits'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Cadastro de KITS
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/admin/cadastro-preco'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Cadastro de Preço
                     </Link>
                  )}
               </Menu.Item>
            </div>
            <div className='p-1'>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/trocaSenha'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Trocar senha
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/logout'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Sair
                     </Link>
                  )}
               </Menu.Item>
            </div>
         </Menu.Items>
      </Menu>
   )
}

export const MenuUser = (props: IMenu) => {
   const { user } = props
   return (
      <Menu as='div' className='relative'>
         <Menu.Button className='rounded-md bg-gray-700 px-3 py-2 text-sm font-medium text-white hover:bg-gray-600'>
            {user.full_name}
         </Menu.Button>
         <Menu.Items className='absolute right-0 mt-2 w-48 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='p-1'>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/trocaSenha'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Trocar senha
                     </Link>
                  )}
               </Menu.Item>
               <Menu.Item>
                  {({ active }) => (
                     <Link
                        to='/logout'
                        className={`${
                           active ? 'bg-gray-100' : ''
                        } group flex w-full items-center rounded-md p-2 text-sm text-gray-900`}
                     >
                        Sair
                     </Link>
                  )}
               </Menu.Item>
            </div>
         </Menu.Items>
      </Menu>
   )
}

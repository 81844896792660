import React from 'react'
import { FaTrashAlt } from 'react-icons/fa'

interface ProdutoProps {
   index: number
   produto: { id: string; Description: string; quantidade: number }
   todosProdutos: { id: string; produto: string }[]
   removerProduto: (index: number) => void
   atualizarProduto: (
      index: number,
      produtoId: string,
      Description: string
   ) => void
   atualizarQuantidade: (index: number, quantidade: number) => void
}

const Produto: React.FC<ProdutoProps> = ({
   index,
   produto,
   todosProdutos,
   removerProduto,
   atualizarProduto,
   atualizarQuantidade
}) => {
   console.log(todosProdutos)
   return (
      <div className='my-2 flex items-center'>
         <select
            className='mr-4 rounded border p-2'
            value={produto.id}
            onChange={e => {
               const selectedProduct = todosProdutos.find(
                  p => p.id === e.target.value
               )
               atualizarProduto(
                  index,
                  e.target.value,
                  selectedProduct?.produto || ''
               )
            }}
         >
            <option value=''>Selecione um produto</option>
            {todosProdutos.map(p => (
               <option key={p.id} value={p.id}>
                  {p.produto}
               </option>
            ))}
         </select>
         <button
            className='rounded bg-gray-200 p-2'
            onClick={() => atualizarQuantidade(index, produto.quantidade - 1)}
         >
            -
         </button>
         <span className='mx-2'>{produto.quantidade}</span>
         <button
            className='rounded bg-gray-200 p-2'
            onClick={() => atualizarQuantidade(index, produto.quantidade + 1)}
         >
            +
         </button>
         <button
            className='ml-4 flex items-center rounded bg-red-500 p-2 text-white'
            onClick={() => removerProduto(index)}
         >
            <FaTrashAlt />
         </button>
      </div>
   )
}

export default Produto

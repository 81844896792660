import React from 'react'
type Produto = {
   area: string
   linha: string
   codigo: string
   produto: string
   unidadeVenda: string
   espessura: string
   consumo: string
   rendimento: string
   quantidade: number
   ipi: string
   precoUnitario: string
   total: string
   precoM2: string
}

interface Orcamento {
   numero: string
   dataEmissao: string
   produtos: Produto[]
}

const CustoMaterial: React.FC<{ orcamento: Orcamento }> = ({ orcamento }) => {
   const mergeCells = (produtos: Produto[]) => {
      let mergedData: {
         area: string
         linha: string
         espessura: string
         produtos: Produto[]
      }[] = []

      produtos.forEach(produto => {
         const foundGroup = mergedData.find(
            group =>
               group.area === produto.area &&
               group.linha === produto.linha &&
               group.espessura === produto.espessura
         )

         if (foundGroup) {
            foundGroup.produtos.push(produto)
         } else {
            mergedData.push({
               area: produto.area,
               linha: produto.linha,
               espessura: produto.espessura,
               produtos: [produto]
            })
         }
      })

      return mergedData
   }

   const parsePrice = (priceStr: string): number => {
      const numberStr = priceStr
         .replace('R$', '')
         .replace('.', '')
         .replace(',', '.')
         .trim()
      return parseFloat(numberStr)
   }

   const parseArea = (areaStr: string): number => {
      const numberStr = areaStr.replace('m²', '').replace(',', '.').trim()
      return parseFloat(numberStr)
   }

   const mergedData = mergeCells(orcamento.produtos)

   return (
      <div className='mb-4 border border-gray-300 p-4'>
         <h2 className='titulo bg-blue-800 py-1 text-center text-lg font-semibold text-white'>
            CUSTO DO MATERIAL / UNIDADE DE VENDA
         </h2>
         <table className='min-w-full divide-y divide-gray-200'>
            <thead className='bg-gray-50'>
               <tr>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Área
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Linha
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Código
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Produto
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Un. / Venda
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Espessura
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Consumo kg/m²
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Rendimento
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Qtd. de Produtos
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     IPI
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Preço Unitário
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Total (Por Produto)
                  </th>
                  <th className='px-4 py-2 text-xs font-medium uppercase tracking-wider text-gray-500'>
                     Preço M²
                  </th>
               </tr>
            </thead>
            <tbody className='divide-y divide-gray-200 bg-white'>
               {mergedData.map((group, groupIndex) => {
                  return (
                     <React.Fragment key={groupIndex}>
                        {group.produtos.map(
                           (produto: Produto, index: number) => {
                              console.log('AQUI', group.produtos.length)
                              const total =
                                 produto.quantidade *
                                 parsePrice(produto.precoUnitario)
                              const precoM2 = total / parseArea(produto.area)
                              return (
                                 <tr key={index}>
                                    {index === 0 && (
                                       <>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.area}
                                          </td>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.linha}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.codigo}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.produto}
                                          </td>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.unidadeVenda}
                                          </td>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.espessura}
                                          </td>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.consumo}
                                          </td>
                                          <td
                                             style={{}}
                                             rowSpan={group.produtos.length}
                                             className='px-4 py-2 text-center align-middle text-sm text-gray-500'
                                          >
                                             {produto.rendimento}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.quantidade}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.ipi}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.precoUnitario}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {total}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             R$ {precoM2.toFixed(2)}
                                          </td>
                                       </>
                                    )}
                                    {index !== 0 && (
                                       <>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.codigo}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.produto}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.quantidade}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.ipi}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {produto.precoUnitario}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             {total}
                                          </td>
                                          <td
                                             style={{}}
                                             className='px-4 py-2 text-sm text-gray-500'
                                          >
                                             R$ {precoM2.toFixed(2)}
                                          </td>
                                       </>
                                    )}
                                 </tr>
                              )
                           }
                        )}
                     </React.Fragment>
                  )
               })}
            </tbody>
         </table>
         <div className='mt-4'>
            <p className='font-semibold'>TOTAL GERAL DE PRODUTOS: "TESTE"</p>
         </div>
      </div>
   )
}

export default CustoMaterial

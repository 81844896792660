export const calculoPreco = (dados: any) => {
   const preco = dados.preco * 1.18
   return preco
}

export const calculoVolumeKit = (dados: any) => {
   const volumeKit =
      parseFloat(dados.PesoKit) / parseFloat(dados.pesoEspecificoABC)
   return volumeKit
}

export const calculoConsumo = (dados: any) => {
   const { pesoEspecificoABC, EPS, R, h } = dados

   const EPSFloat = parseFloat(EPS)
   const RFloat = parseFloat(R)
   const hFloat = parseFloat(h)

   const calc1 = pesoEspecificoABC * 1000
   const calc2 = (EPSFloat / 1000000) * (RFloat / 100 + hFloat / 100)
   const calc3 = (RFloat / 100) ** 2 * (1 - Math.PI / 4)
   const calc4 = calc2 + calc3
   const calc5 = calc1 * calc4
   const resultado = calc5
   return resultado
}

export const calculoRendimentoTeoricoL = (dados: any) => {
   const rendimentoTeorico =
      1 /
      (((dados.EPS / 1000000) * (dados.R / 100 + dados.h / 100) +
         (dados.R / 100) ** 2 * (1 - Math.PI / 4)) *
         1000)
   return rendimentoTeorico
}

export const calculoRendimentoTeoricoKit = (dados: any) => {
   const rendimentoTeoricoKit =
      calculoRendimentoTeoricoL(dados) * calculoVolumeKit(dados)
   return rendimentoTeoricoKit
}

export const calculoKG = (dados: any) => {
   const KG = dados.mLinear * calculoConsumo(dados)
   return KG
}

export const calculoUnidadesKits = (dados: any) => {
   const unidadesKits = calculoKG(dados) / parseFloat(dados.PesoKit)
   return unidadesKits
}

export const precoRSKG = (dados: any) => {
   const unidadesKits = precoRSKit(dados) / parseFloat(dados.PesoKit)
   return unidadesKits
}

export const precoESMlinear = (dados: any) => {
   const unidadesKits = precoRSKit(dados) / calculoRendimentoTeoricoKit(dados)
   return unidadesKits
}

export const precoRSL = (dados: any) => {
   const unidadesKits = precoRSKit(dados) / calculoVolumeKit(dados)
   return unidadesKits
}

export const precoRSKit = (dados: any) => {
   const unidadesKits = dados.Preco
   return parseFloat(unidadesKits)
}

import CadastroPrecoForm from '../../components/Forms/CadastroPrecoForm'
import AdminNavBar from '../../components/NavBar/navBar'

const CadastroPreco: React.FC = () => {
   return (
      <div className='text-center'>
         <AdminNavBar />
         <CadastroPrecoForm />
      </div>
   )
}
export default CadastroPreco

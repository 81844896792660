import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../images/logo.svg'
import { MenuAdmin, MenuUser } from './menuUser'

const AdminNavBar: React.FC = () => {
   const agentString = sessionStorage.getItem('selectedAgent')
   const agentSelected = agentString ? JSON.parse(agentString) : null

   return (
      <nav className='flex items-center justify-between bg-gray-800 p-4 text-white'>
         <div className='flex-grow-0'>
            <Link to='/'>
               <img src={logo} alt='Logo' className='h-8' />
            </Link>
         </div>
         <ul className='flex flex-grow justify-center space-x-4'>
            <li>
               <Link
                  to='/'
                  className='rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700'
               >
                  Home
               </Link>
            </li>
            <li>
               <Link
                  to='/piso'
                  className='rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700'
               >
                  Piso
               </Link>
            </li>
            <li>
               <Link
                  to='/rodape'
                  className='rounded-md px-3 py-2 text-sm font-medium hover:bg-gray-700'
               >
                  Rodapé
               </Link>
            </li>
         </ul>
         {agentSelected.permission === 'admin' ? (
            <MenuAdmin user={agentSelected} />
         ) : (
            <MenuUser user={agentSelected} />
         )}
      </nav>
   )
}

export default AdminNavBar

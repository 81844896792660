// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding: 1.25rem;

    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.footer-header {

    margin-bottom: 1.25rem;

    text-align: center;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.footer-content {

    display: flex;

    justify-content: space-between
}

.contact-info {

    width: 33.333333%;

    text-align: center
}

.contact-info .logo {

    margin-left: auto;

    margin-right: auto;

    margin-bottom: 0.75rem;

    width: 6rem
}

.contact-details p {

    margin-top: 0.25rem;

    margin-bottom: 0.25rem
}

.social-media {

    margin-top: 0.5rem;

    display: flex;

    justify-content: center
}

.social-media > :not([hidden]) ~ :not([hidden]) {

    --tw-space-x-reverse: 0;

    margin-right: calc(0.75rem * var(--tw-space-x-reverse));

    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
}

.social-media a {

    text-decoration-line: none
}

.linkedin {

    --tw-text-opacity: 1;

    color: rgb(29 78 216 / var(--tw-text-opacity))
}

.youtube {

    --tw-text-opacity: 1;

    color: rgb(220 38 38 / var(--tw-text-opacity))
}

.instagram {

    --tw-text-opacity: 1;

    color: rgb(219 39 119 / var(--tw-text-opacity))
}

.product-segments {

    display: flex;

    width: 66.666667%;

    flex-wrap: wrap;

    justify-content: space-between
}

.segment {

    margin-bottom: 1.25rem;

    width: 50%
}

.segment h3 {

    margin-bottom: 0.5rem;

    font-size: 1.125rem;

    line-height: 1.75rem;

    font-weight: 600
}

.segment-item {

    margin-bottom: 0.25rem;

    font-size: 0.875rem;

    line-height: 1.25rem
}

.footer-link {

    margin-top: 1.25rem;

    text-align: center
}

.footer-link a {

    --tw-text-opacity: 1;

    color: rgb(37 99 235 / var(--tw-text-opacity));

    text-decoration-line: none
}
`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AACG;;IAAA,kBAA6B;;IAA7B,yDAA6B;;IAA7B,gBAA6B;;IAA7B;AAA6B;;AAI7B;;IAAA,sBAA+B;;IAA/B,kBAA+B;;IAA/B,mBAA+B;;IAA/B;AAA+B;;AAI/B;;IAAA,aAA2B;;IAA3B;AAA2B;;AAI3B;;IAAA,iBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,iBAAwB;;IAAxB,kBAAwB;;IAAxB,sBAAwB;;IAAxB;AAAwB;;AAIxB;;IAAA,mBAAW;;IAAX;AAAW;;AAIX;;IAAA,kBAAyC;;IAAzC,aAAyC;;IAAzC;AAAyC;;AAAzC;;IAAA,uBAAyC;;IAAzC,uDAAyC;;IAAzC;AAAyC;;AAIzC;;IAAA;AAAmB;;AAInB;;IAAA,oBAAoB;;IAApB;AAAoB;;AAIpB;;IAAA,oBAAmB;;IAAnB;AAAmB;;AAInB;;IAAA,oBAAoB;;IAApB;AAAoB;;AAIpB;;IAAA,aAA2C;;IAA3C,iBAA2C;;IAA3C,eAA2C;;IAA3C;AAA2C;;AAI3C;;IAAA,sBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA,qBAAiC;;IAAjC,mBAAiC;;IAAjC,oBAAiC;;IAAjC;AAAiC;;AAIjC;;IAAA,sBAAmB;;IAAnB,mBAAmB;;IAAnB;AAAmB;;AAInB;;IAAA,mBAAuB;;IAAvB;AAAuB;;AAIvB;;IAAA,oBAAiC;;IAAjC,8CAAiC;;IAAjC;AAAiC","sourcesContent":[".footer-container {\r\n   @apply bg-white p-5 font-sans;\r\n}\r\n\r\n.footer-header {\r\n   @apply mb-5 text-center text-sm;\r\n}\r\n\r\n.footer-content {\r\n   @apply flex justify-between;\r\n}\r\n\r\n.contact-info {\r\n   @apply w-1/3 text-center;\r\n}\r\n\r\n.contact-info .logo {\r\n   @apply mx-auto mb-3 w-24;\r\n}\r\n\r\n.contact-details p {\r\n   @apply my-1;\r\n}\r\n\r\n.social-media {\r\n   @apply mt-2 flex justify-center space-x-3;\r\n}\r\n\r\n.social-media a {\r\n   @apply no-underline;\r\n}\r\n\r\n.linkedin {\r\n   @apply text-blue-700;\r\n}\r\n\r\n.youtube {\r\n   @apply text-red-600;\r\n}\r\n\r\n.instagram {\r\n   @apply text-pink-600;\r\n}\r\n\r\n.product-segments {\r\n   @apply flex w-2/3 flex-wrap justify-between;\r\n}\r\n\r\n.segment {\r\n   @apply mb-5 w-1/2;\r\n}\r\n\r\n.segment h3 {\r\n   @apply mb-2 text-lg font-semibold;\r\n}\r\n\r\n.segment-item {\r\n   @apply mb-1 text-sm;\r\n}\r\n\r\n.footer-link {\r\n   @apply mt-5 text-center;\r\n}\r\n\r\n.footer-link a {\r\n   @apply text-blue-600 no-underline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from 'react'
import Footer from '../Footer/footer'
import { IProduct } from '../../interfaces/IProduct'
import {
   getProduto,
   getProdutos
} from '../../services/produtos/produtosService'
import { IKits } from '../../interfaces/IKits'
import {
   getkits,
   saveKit,
   saveKitProduto
} from '../../services/produtos/kitsService'
import { useNavigate } from 'react-router-dom'

const CadastroKitsForm: React.FC = (props: any) => {
   const navigate = useNavigate()
   const [product, setProduct] = useState<IKits>({
      sku: '',
      kit: '',
      productId: '',
      idKit: '',
      preco: ''
   })
   const [loading, setLoading] = useState<boolean>(true)
   const [produto, setProduto] = useState<any>({})
   const [produtos, setProdutos] = useState<any>([])
   const [kits, setKits] = useState<{ id: number; kit: string }[]>([])
   const [newKitName, setNewKitName] = useState<string>('')
   const [selectedKit, setSelectedKit] = useState<string>('')

   useEffect(() => {
      const fetchProdutos = async () => {
         try {
            const response = await getProdutos()
            setProdutos(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         } finally {
            setLoading(false)
         }
      }
      fetchProdutos()
   }, [])

   const handleKitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = e.target.value
      if (selectedValue === 'new') {
         setNewKitName('')
         setSelectedKit('new')
      } else {
         const kit = kits.find(kit => kit.id === Number(selectedValue))
         console.log(e.target.value)
         setProduct(prevProduct => ({
            ...prevProduct,
            idKit: Number(selectedValue),
            kit: kit ? kit.kit : ''
         }))
         setSelectedKit(selectedValue)
      }
   }

   useEffect(() => {
      const fetchKits = async () => {
         try {
            const kitsData = await getkits()
            setKits(kitsData)
         } catch (error) {
            console.error('Erro ao buscar kits:', error)
         }
      }
      fetchKits()
   }, [])

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      const { name, value } = e.target
      setProduct({ ...product, [name]: value })
   }

   const getProduct = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      if (e.target.value === '') {
         return null
      }
      const fetchProdutos = async () => {
         try {
            const response = await getProduto(Number(e.target.value))
            setProduct({
               ...product,
               productId: response.id,
               sku: response.CPA
            })
            setProduto(response)
            setLoading(false)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         }
      }
      fetchProdutos()
   }

   const handleNewKitNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target
      setNewKitName(value)
      setProduct(prevProduct => ({
         ...prevProduct,
         kit: value,
         idKit: ''
      }))
   }

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      setLoading(true)
      if (product.idKit === '') {
         const data = {
            kit: product.kit
         }

         try {
            const novokit = await saveKit(data)
            setProduct(prevProduct => ({
               ...prevProduct,
               idKit: novokit.id
            }))

            setTimeout(async () => {
               await saveKitProduto({
                  ...product,
                  idKit: novokit.id
               })
               navigate('/admin/cadastro-preco', {
                  state: { dados: product, produto: props.data }
               })
               setLoading(false)
            }, 0)
         } catch (error) {
            console.error('Erro ao salvar o kit:', error)
         }
      } else {
         try {
            await saveKitProduto(product)
            navigate('/admin/cadastro-preco', {
               state: { dados: product, produto: props.data }
            })
         } catch (error) {
            console.error('Erro ao salvar o kit produto:', error)
         }
      }

      console.log(product)
   }

   return (
      <div className='form-container'>
         {loading}
         <h1>Cadastro de Kits</h1>
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            <div className='mb-6 grid gap-6 md:grid-cols-2'>
               <div>
                  <label
                     htmlFor='productId'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Produto *
                  </label>
                  <select
                     required
                     name='productId'
                     id='productId'
                     value={produto.id}
                     onChange={e => getProduct(e)}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  >
                     <option value=''>Selecione um produto</option>
                     {produtos.map((produto: IProduct) => (
                        <option key={produto.id} value={produto.id}>
                           {produto.produto} - {produto.CPA}
                        </option>
                     ))}
                  </select>
               </div>
               <div>
                  <label
                     htmlFor='sku'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     SKU *
                  </label>
                  <input
                     type='text'
                     name='sku'
                     id='sku'
                     value={product.sku}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='kit'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Kit *
                  </label>
                  <select
                     required
                     name='kit'
                     id='kit'
                     value={selectedKit}
                     onChange={handleKitChange}
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  >
                     <option value=''>Selecione Abaixo</option>
                     <option value='new'>Adicionar novo kit</option>
                     {kits.map(kit => (
                        <option key={kit.id} value={kit.id}>
                           {kit.kit}
                        </option>
                     ))}
                  </select>
                  {selectedKit === 'new' && (
                     <input
                        type='text'
                        name='newKit'
                        id='newKit'
                        value={newKitName}
                        onChange={handleNewKitNameChange}
                        required
                        placeholder='Digite o nome do novo kit'
                        className='mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                     />
                  )}
               </div>
               <div>
                  <label
                     htmlFor='preco'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Preço *
                  </label>
                  <input
                     type='number'
                     name='preco'
                     id='preco'
                     value={product.preco}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
            </div>
            <p className='text-sm text-red-500'>
               Campos com * são obrigatórios
            </p>
            <button type='submit' className='button px-8 py-4'>
               Cadastrar Kit
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default CadastroKitsForm

import React, { useEffect, useState } from 'react'
import Footer from '../Footer/footer'
import { IPreco } from '../../interfaces/IPreco'
import { getkitById, getkits } from '../../services/produtos/kitsService'
import { saveProdutoPrice } from '../../services/produtos/produtosService'
import { useNavigate } from 'react-router-dom'

const CadastroPrecoForm: React.FC = (props: any) => {
   const [product, setProduct] = useState<IPreco>({
      preco: 0,
      pesoSKU: 0,
      pesoKIT: 0,
      nvv: 0,
      productId: '',
      skus: '',
      descricao: ''
   })
   const [loading, setLoading] = useState<boolean>(true)
   const [produto, setProduto] = useState<any>({})
   const [produtos, setProdutos] = useState<any>([])
   const navigate = useNavigate()

   useEffect(() => {
      const fetchProdutos = async () => {
         try {
            const response = await getkits()
            setProdutos(response)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         } finally {
            setLoading(false)
         }
      }
      fetchProdutos()
   }, [])

   const handleChange = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      const { name, value } = e.target
      setProduct({ ...product, [name]: value })
   }

   const getProduct = (
      e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
   ) => {
      if (e.target.value === '') {
         return null
      }
      const fetchProdutos = async () => {
         try {
            const response = await getkitById(Number(e.target.value))
            setProduto(response)
            const skuString = response.SKUs.map((item: any) => item.sku).join(
               ', '
            )
            setProduct({
               ...product,
               skus: skuString,
               productId: e.target.value,
               preco: response.preco_kit
            })
            setLoading(false)
         } catch (error) {
            console.error('Erro ao buscar produtos:', error)
         }
      }
      fetchProdutos()
   }

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      await saveProdutoPrice(product)
      navigate('/home')
      console.log(product)
   }

   return (
      <div className='form-container'>
         {loading}
         <h1>Cadastro de Preço</h1>
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            <div className='mb-6 grid gap-6 md:grid-cols-2'>
               <div>
                  <label
                     htmlFor='productId'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Produto *
                  </label>
                  <select
                     name='productId'
                     id='productId'
                     value={produto.id}
                     onChange={e => getProduct(e)}
                     className='mt-1 block w-full rounded-md border border-gray-300 bg-white px-3 py-2 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                  >
                     <option value=''>Selecione um produto</option>
                     {produtos.map((produto: any) => (
                        <option key={produto.id} value={produto.id}>
                           {produto.kit}
                        </option>
                     ))}
                  </select>
               </div>
               <div>
                  <label
                     htmlFor='sku'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     SKU *
                  </label>
                  <input
                     type='text'
                     name='sku'
                     id='sku'
                     value={product.skus}
                     onChange={handleChange}
                     required
                     disabled
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div className='relative'>
                  <label
                     htmlFor='preco'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Preço *
                  </label>
                  <input
                     type='text'
                     name='preco'
                     id='preco'
                     value={product.preco}
                     onChange={handleChange}
                     required
                     disabled
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='pesoSKU'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Peso SKU *
                  </label>
                  <input
                     type='text'
                     name='pesoSKU'
                     id='pesoSKU'
                     value={product.pesoSKU}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='pesoKIT'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     Peso kit (kg) *
                  </label>
                  <input
                     type='text'
                     name='pesoKIT'
                     id='pesoKIT'
                     value={product.pesoKIT}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
               <div>
                  <label
                     htmlFor='nvv'
                     className='mb-2 block text-sm font-medium text-gray-900 dark:text-white'
                  >
                     NVV (%) *
                  </label>
                  <input
                     type='nvv'
                     name='nvv'
                     id='nvv'
                     value={product.nvv}
                     onChange={handleChange}
                     required
                     className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                  />
               </div>
            </div>
            <p className='text-sm text-red-500'>
               Campos com * são obrigatórios
            </p>
            <button type='submit' className='button px-8 py-4'>
               Cadastrar Preço
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default CadastroPrecoForm

export const calculoCKG = (dados: any) => {
   const CKG = (dados.espessura * (calculoPEABC(dados) / 1000)).toFixed(2)
   return parseFloat(CKG)
}

export const calculoQTDAGREGADOLT = (dados: any) => {
   const QTDAGREGADOLT = (dados.quantidade / dados.PEAMIX).toFixed(2)
   return parseFloat(QTDAGREGADOLT)
}

export const calculoPECAB = (dados: any) => {
   const PECAB = (calculoQTDAGREGADOLT(dados) / 3.6).toFixed(2)
   return parseFloat(PECAB)
}

export const calculoMASCAB = (dados: any) => {
   const MASCAB = (dados.quantidade / (3.6 * dados.PEAB)).toFixed(2)
   return parseFloat(MASCAB)
}

export const calculoVOLCAB = (dados: any) => {
   const VOLCAB = (calculoQTDAGREGADOLT(dados) / 3.6).toFixed(2)
   return parseFloat(VOLCAB)
}

export const calculoPEABC = (dados: any) => {
   const PEABC = (
      (dados.VAVB * dados.PEA +
         dados.PEB +
         (dados.VAVB + 1) * calculoVOLCAB(dados) * dados.PEAMIX) /
      (dados.VAVB + 1 + (dados.VAVB + 1) * calculoVOLCAB(dados))
   ).toFixed(2)
   return parseFloat(PEABC)
}

export const calculoNVMABC = (dados: any) => {
   const NVMABC = (
      (dados.VAVB * (dados.PEB / dados.PEA) * dados.NVMB +
         dados.NVMB +
         (dados.VAVB * (dados.PEB / dados.PEA) + 1) *
            calculoVOLCAB(dados) *
            100) /
      (dados.VAVB * (dados.PEB / dados.PEA) +
         1 +
         (dados.VAVB * (dados.PEB / dados.PEA) + 1) * calculoVOLCAB(dados))
   ).toFixed(2)
   return parseFloat(NVMABC)
}

export const calculoNVVABC = (dados: any) => {
   const NVVABC = (
      (dados.VAVB * dados.NVVA +
         dados.NVMB +
         (dados.VAVB + 1) * calculoVOLCAB(dados) * 100) /
      (dados.VAVB + 1 + (dados.VAVB + 1) * calculoVOLCAB(dados))
   ).toFixed(2)
   return parseFloat(NVVABC)
}

export const calculoPKR = (dados: any) => {
   const AE8 = calculoNVVABC(dados)
   const V8 = dados.espessura
   const Y8 = calculoQTDAGREGADOLT(dados)
   const PKR = (((AE8 * 10) / V8) * (3.6 + Y8)).toFixed(2)
   return parseFloat(PKR)
}

export const calculoPKCAT = (dados: any) => {
   const PKCAT = (dados.area / calculoPKR(dados)).toFixed(2)
   return parseFloat(PKCAT)
}

export const calculoQUCA = (dados: any) => {
   const QUCA = calculoPKCAT(dados) * 1
   return Math.ceil(QUCA)
}

export const calculoQUCB = (dados: any) => {
   const QUCB = calculoPKCAT(dados) * 1
   return Math.ceil(QUCB)
}

export const calculoQUAGREGADO = (dados: any) => {
   const baseCalculation = calculoPKCAT(dados) * (dados.quantidade / 11.9)
   const CalcuPorcentagem = 0
   const QUAGREGADO = baseCalculation * (1 + CalcuPorcentagem)
   return Math.ceil(QUAGREGADO)
}

export const calculoQKGA = (dados: any) => {
   const QKGA = (
      calculoQUCA(dados) *
      (3.6 * (dados.VAVB / (1 + dados.VAVB))) *
      dados.PEA
   ).toFixed(4)
   return parseFloat(QKGA)
}

export const calculoQKGB = (dados: any) => {
   const QKGB = (
      calculoQUCB(dados) *
      (3.6 / (1 + dados.VAVB)) *
      dados.PEB
   ).toFixed(4)
   return parseFloat(QKGB)
}

export const calculoQKGAGREGADO = (dados: any) => {
   const QKGAGREGADO = (calculoQUAGREGADO(dados) * dados.PSA).toFixed(4)
   return parseFloat(QKGAGREGADO)
}

import axios from 'axios'

const { REACT_APP_API_URL } = process.env

const api = axios.create({
   baseURL: 'https://xcalcbackend.ldtsistemas.com.br/',
   timeout: 15000
})

export const token = sessionStorage.getItem('token')

export default api

import React, { useState } from 'react'
import { IProduct } from '../../interfaces/IProduct'
import Footer from '../Footer/footer'
import { saveProduto } from '../../services/produtos/produtosService'
import { useNavigate } from 'react-router-dom'

const ProductForm: React.FC = () => {
   const navigate = useNavigate()
   const [product, setProduct] = useState<IProduct>({
      produto: '',
      CPA: '',
      CPB: '',
      MIX: '',
      linha: '',
      PENVMIX: 0,
      VAVB: 0,
      VABl: 0,
      PEAMIX: 0,
      PEA: 0,
      PEB: 0,
      NVMMIX: 0,
      NVMA: 0,
      NVMB: 0,
      NVVMIX: 0,
      NVVA: 0,
      NVVB: 0,
      PSA: 0,
      description: ''
   })

   const [image, setImage] = useState<File | null>(null)

   const handleChange = (e: any) => {
      const { name, value } = e.target
      setProduct({ ...product, [name]: value })
   }

   const handleImageChange = (e: any) => {
      if (e.target.files && e.target.files[0]) {
         setProduct({ ...product, imagem: e.target.files[0] })
         setImage(e.target.files[0])
      }
   }

   const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault()
      const formData = new FormData()
      formData.append('produto', product.produto)
      formData.append('CPA', product.CPA)
      formData.append('CPB', product.CPB)
      formData.append('MIX', product.MIX)
      formData.append('linha', product.linha)
      formData.append('PENVMIX', product.PENVMIX.toString())
      formData.append('VAVB', product.VAVB.toString())
      formData.append('VABl', product.VABl.toString())
      formData.append('PEAMIX', product.PEAMIX.toString())
      formData.append('PEA', product.PEA.toString())
      formData.append('PEB', product.PEB.toString())
      formData.append('NVMMIX', product.NVMMIX.toString())
      formData.append('NVMA', product.NVMA.toString())
      formData.append('NVMB', product.NVMB.toString())
      formData.append('NVVMIX', product.NVVMIX.toString())
      formData.append('NVVA', product.NVVA.toString())
      formData.append('NVVB', product.NVVB.toString())
      formData.append('PSA', product.PSA.toString())
      formData.append('descricao', product.description)
      console.log(product)

      if (image) {
         formData.append('image', image)
      }

      await saveProduto(formData)
      navigate('/admin/cadastro-kits', { state: { dados: product } })
   }

   const getFieldsInGroups = (fields: string[]) => {
      const groupSize = 4
      const groups = []
      for (let i = 0; i < fields.length; i += groupSize) {
         groups.push(fields.slice(i, i + groupSize))
      }
      return groups
   }

   const fieldGroups = getFieldsInGroups(Object.keys(product))

   return (
      <div className='form-container'>
         <h1>Cadastro de Produto</h1>
         <form
            onSubmit={handleSubmit}
            className='mx-auto mt-8 flex max-w-4xl flex-col space-y-4'
         >
            {fieldGroups.map((group, idx) => (
               <div
                  key={idx + 1}
                  className='form-field -mx-3 mb-6 flex flex-wrap'
               >
                  {group.map(key => {
                     let keyRequired
                     if (key === 'linha') {
                        keyRequired = false
                     } else {
                        keyRequired = true
                     }
                     if (key !== 'imagem' && key !== 'description')
                        return (
                           <div
                              key={key}
                              className='mb-6 w-full px-3 md:mb-0 md:w-1/4'
                           >
                              <label
                                 htmlFor={key}
                                 className='block text-sm font-medium uppercase text-gray-700'
                              >
                                 {key} {keyRequired ? '*' : ''}
                              </label>
                              <input
                                 required={keyRequired}
                                 type='text'
                                 name={key}
                                 id={key}
                                 value={(product as any)[key]}
                                 onChange={handleChange}
                                 className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
                              />
                           </div>
                        )
                  })}
               </div>
            ))}
            <div className='mb-6 w-full px-3 md:mb-0 md:w-1/4'>
               <label
                  htmlFor='image'
                  className='block text-sm font-medium text-gray-700'
               >
                  Imagem do Produto
               </label>
               <input
                  required
                  type='file'
                  name='image'
                  id='image'
                  onChange={handleImageChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <div className='form-field -mx-3 mb-6 flex flex-wrap'>
               <label
                  htmlFor='description'
                  className='block text-sm font-medium uppercase text-gray-700'
               >
                  Descrição *
               </label>
               <textarea
                  required
                  name='description'
                  id='description'
                  value={product.description}
                  onChange={handleChange}
                  className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
               />
            </div>
            <button type='submit' className='button px-8 py-4'>
               Cadastrar Produto
            </button>
         </form>
         <Footer />
      </div>
   )
}

export default ProductForm

import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { isAuthenticated } from '../services/auth/Authenticated'

interface AuthWrapperProps {
   children: React.ReactNode
   from: any
}

interface PrivateRouteProps {
   component: React.ReactNode
}

export function AuthWrapper({ children, from }: AuthWrapperProps) {
   return isAuthenticated() ? (
      children
   ) : (
      <Navigate to='/login' replace state={{ from }} />
   )
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component }) => {
   const location = useLocation()

   if (!isAuthenticated()) {
      return <Navigate to='/login' replace state={{ from: location }} />
   }
   return <>{component}</>
}

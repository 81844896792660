import Orcamento from '../components/Orcamento/Orcamento'
import AdminNavBar from '../components/NavBar/navBar'

const CadastroOrcamento: React.FC = () => {
   return (
      <div className='text-center'>
         <AdminNavBar />
         <Orcamento />
      </div>
   )
}
export default CadastroOrcamento

import React from 'react'
import NavBar from '../components/NavBar/navBar'
import { Link } from 'react-router-dom'

const Home: React.FC = () => {
   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      console.log()
   }
   const agentString = sessionStorage.getItem('selectedAgent')
   const agentSelected = agentString ? JSON.parse(agentString) : null
   if (agentSelected.permission === 'usuario') {
      return (
         <div className='text-center'>
            <NavBar />
            <div
               className='form-container homeLayout'
               style={{ marginBottom: '2rem' }}
            >
               <h1>Pagina de Testes - Funções Usuário</h1>
               <hr />
               <h2>Cadastro de Orçamento</h2>
               <Link to='/piso' className='buttonHome px-8 py-4'>
                  1º Cadastrar Orçamento PISO
               </Link>
               <Link to='/rodape' className='buttonHome px-8 py-4'>
                  1º Cadastrar Orçamento RODAPE
               </Link>
            </div>
         </div>
      )
   }
   return (
      <div className='text-center'>
         <NavBar />
         <div
            className='form-container homeLayout'
            style={{ marginBottom: '2rem' }}
         >
            <h1>Pagina de Testes - Funções ADM</h1>
            <h3> Produtos</h3>
            <hr />
            <h2>Cadastrar produto igual a primeira tabela excel</h2>
            <Link
               to='/admin/cadastro-produtos'
               className='buttonHome px-8 py-4'
            >
               1º Cadastrar Produto
            </Link>
            <h2>Vinculação do produto a um kit para definição de preço</h2>
            <Link to='/admin/cadastro-kits' className='buttonHome px-8 py-4'>
               2º Cadastrar Kit
            </Link>
            <h2>Cadastro de KIT's conforme planilha cotação cove</h2>
            <Link to='/admin/cadastro-preco' className='buttonHome px-8 py-4'>
               3º Cadastrar Preço
            </Link>
            <hr />
            <h3> Clientes</h3>
            <h2>Cadastrar Clientes - preencher todos os campos</h2>
            <Link
               to='/admin/cadastro-clientes'
               className='buttonHome px-8 py-4'
            >
               4º Cadastrar Cliente
            </Link>
            <hr />
            <h3> Usuarios</h3>
            <h2>Cadastrar Usuarios - preencher todos os campos</h2>
            <Link
               to='/admin/cadastro-usuarios'
               className='buttonHome px-8 py-4'
            >
               5º Cadastrar Usuarios
            </Link>
         </div>
      </div>
   )
}
export default Home

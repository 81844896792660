import api, { token } from '../auth/api'

export const saveCalculo = (data: any) => {
   return api
      .post('/budget/save', data, {
         headers: {
            Authorization: `Bearer ${token}`
         }
      })
      .then(response => {
         return response.data
      })
      .catch(error => {
         console.log('Erro ao salvar o calculo:', error)
         throw error
      })
}

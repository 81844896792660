import React, { useState } from 'react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'
import { FaFilePdf } from 'react-icons/fa'
import FooterImpressao from '../Footer/FooterImpressao'

import logo2 from '../../images/logo2.svg'
import LogoSVG from '../Layouts/svg/Logo'
import LogoIISVG from '../Layouts/svg/LogoII'
import Header from './Header'
import CustoMaterial from './custoMaterial'

const dados = {
   cliente: {
      nome: 'RAC SERVIÇOS EIRELLI',
      contato: 'MARCELO LUCENA',
      email: 'Lucenatec.rac@hotmail.com',
      cidade: 'RECIFE',
      bairro: 'POCO',
      cep: '52.060-490',
      cnpj: 'BRLACG_23900862',
      fone: '81 9155-3915',
      obra: 'FABRICA DE RAÇÃO - 900 M² DE REVESTIMENTO EM URETANO COM 4mm.'
   },
   sherwinWilliams: {
      loja: 'SWBR 4K7 JABOATÃO',
      contato: 'DENIS MORAIS',
      email: 'denis.morais@sherwin.com.br',
      endereco:
         'R. Dona Maria de Souza, 322 - Piedade, Jaboatão dos Guararapes - PE, 54400-260',
      cnpj: '60.872.306/0057-14',
      fone: '81 9 9662-1985'
   },
   orcamento: {
      numero: 'XXXXXX',
      dataEmissao: '28/06/2024',
      produtos: [
         {
            area: '900 m²',
            linha: 'URETANO',
            codigo: 'GPULFCA01-BR',
            produto: 'FASTOP MULTI A',
            unidadeVenda: 'KIT 22,00 kg',
            espessura: '4 mm',
            consumo: '6,820 Kg/m²',
            rendimento: '2,33 kit/m²',
            quantidade: 279,
            ipi: '3,25%',
            precoUnitario: 'R$ 95,00',
            total: 'R$ 26.505,00',
            precoM2: 'R$ 93,00'
         },
         {
            area: '900 m²',
            linha: 'URETANO',
            codigo: 'GPULFCB01-BR',
            produto: 'FASTOP MULTI B',
            unidadeVenda: 'KIT 22,00 kg',
            espessura: '4 mm',
            consumo: '6,820 Kg/m²',
            rendimento: '2,33 kit/m²',
            quantidade: 279,
            ipi: '3,25%',
            precoUnitario: 'R$ 120,00',
            total: 'R$ 33.480,00',
            precoM2: ''
         },
         {
            area: '900 m²',
            linha: 'URETANO',
            codigo: 'GP5137CNT-BR',
            produto: 'FASTOP MULTI SL 45',
            unidadeVenda: 'KIT 22,00 kg',
            espessura: '4 mm',
            consumo: '6,820 Kg/m²',
            rendimento: '2,33 kit/m²',
            quantidade: 279,
            ipi: '3,25%',
            precoUnitario: 'R$ 60,00',
            total: 'R$ 16.740,00',
            precoM2: ''
         },
         {
            area: '900 m²',
            linha: 'URETANO',
            codigo: 'GPULFCPMG-BR',
            produto: 'FASTOP MULTI CP MD GRY',
            unidadeVenda: 'KIT 22,00 kg',
            espessura: '4 mm',
            consumo: '6,820 Kg/m²',
            rendimento: '2,33 kit/m²',
            quantidade: 279,
            ipi: '3,25%',
            precoUnitario: 'R$ 25,00',
            total: 'R$ 6.975,00',
            precoM2: ''
         },
         {
            area: '690 m²',
            linha: 'EPOXI',
            codigo: 'FL01RE0010BR01',
            produto: 'RESUFLOR RE - COMP. A',
            unidadeVenda: 'galão 3,60 litros',
            espessura: '100 µm',
            consumo: '0,110 Kg/m²',
            rendimento: '30 kit/m²',
            quantidade: 25,
            ipi: '3,25%',
            precoUnitario: 'R$ 120,00',
            total: 'R$ 5.250,00',
            precoM2: 'R$ 7,61'
         },
         {
            area: '690 m²',
            linha: 'EPOXI',
            codigo: 'FL01RE9010BR03',
            produto: 'RESUFLOR RE - COMP. B',
            unidadeVenda: 'galão 3,60 litros',
            espessura: '100 µm',
            consumo: '0,110 Kg/m²',
            rendimento: '30 kit/m²',
            quantidade: 25,
            ipi: '3,25%',
            precoUnitario: 'R$ 90,00',
            total: '',
            precoM2: ''
         },
         {
            area: '690 m²',
            linha: 'EPOXI',
            codigo: 'FL01TC2010BR01',
            produto: 'RESUFLOR TC CINZA M.N6,5 - COMP. A',
            unidadeVenda: 'galão 3,60 litros',
            espessura: '200 µm',
            consumo: '0,280 Kg/m²',
            rendimento: '14 kit/m²',
            quantidade: 50,
            ipi: '3,25%',
            precoUnitario: 'R$ 190,00',
            total: 'R$ 14.000,00',
            precoM2: 'R$ 20,29'
         },
         {
            area: '690 m²',
            linha: 'EPOXI',
            codigo: 'FL01TC9000BR06',
            produto: 'RESUFLOR TC - COMP. B',
            unidadeVenda: 'galão 3,60 litros',
            espessura: '200 µm',
            consumo: '0,280 Kg/m²',
            rendimento: '14 kit/m²',
            quantidade: 50,
            ipi: '3,25%',
            precoUnitario: 'R$ 90,00',
            total: '',
            precoM2: ''
         }
      ]
   },
   nota: 'O rendimento teórico é calculado com base no teor de sólidos por volume e não inclui fatores de perda na aplicação devido a irregularidades, rugosidade ou porosidade da superfície, geometria das peças, método de aplicação, habilidade e técnica do aplicador, perdas de material durante a preparação, derrames, respingos, diluição além do especificado, condições climáticas e camada excessiva do filme aplicado. Considerar todas as perdas para calcular a quantidade de tinta a ser utilizada.\n*Dados sem agregado. Variam conforme as condições do piso e quantidade de agregado. Consultar assistência técnica para maiores informações ou pelo site www.swprotectivelatam.com.br. Portanto não será aceito devoluções ou haverá reposições sem custo.'
}

const Orcamento: React.FC = () => {
   const [imgPreview, setImgPreview] = useState<string | null>(null)
   const gerarImagem = async () => {
      const element = document.getElementById('orcamento')
      if (!element) {
         console.error('Elemento não encontrado')
         return
      }

      element.style.padding = '20px'
      element.style.margin = '0 auto'
      element.style.display = 'block'
      element.querySelectorAll('table').forEach(el => {
         console.log('ELEMENTO', el)
         el.style.border = 'none'
      })

      const canvas = await html2canvas(element, {
         scale: 2, // Aumenta a escala para capturar em alta resolução
         useCORS: true, // Permite o uso de imagens de outros domínios
         logging: true, // Log das ações para depuração
         //  windowWidth: element.scrollWidth, // Largura total do conteúdo
         windowHeight: element.scrollHeight // Altura total do conteúdo
      })
      const imgData = canvas.toDataURL('image/png')
      setImgPreview(imgData)
   }
   const gerarPDF = async () => {
      const element = document.getElementById('orcamento')
      if (!element) {
         console.error('Elemento não encontrado')
         return
      }

      // Usa html2canvas para capturar o conteúdo do elemento como imagem
      const canvas = await html2canvas(element, {
         scale: 2, // Aumenta a escala para capturar em alta resolução
         useCORS: true // Permite o uso de imagens de outros domínios
      })
      const imgData = canvas.toDataURL('image/png')

      // Cria um novo documento PDF
      const pdf = new jsPDF({
         orientation: 'portrait',
         unit: 'mm',
         format: 'a4'
      })

      // Calcula a largura e altura da imagem no PDF
      const imgWidth = 205 // largura em mm (A4 é 210 mm de largura)
      const pageHeight = 290 // altura em mm (A4 é 297 mm de altura)
      const imgHeight = (canvas.height * imgWidth) / canvas.width
      let heightLeft = imgHeight

      let position = 0

      // Adiciona a imagem ao PDF
      pdf.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight)
      heightLeft -= pageHeight

      // Adiciona novas páginas se necessário
      while (heightLeft >= 0) {
         position = heightLeft - imgHeight
         pdf.addPage()
         pdf.addImage(imgData, 'PNG', 2, position, imgWidth, imgHeight)
         heightLeft -= pageHeight
      }

      // Salva o PDF
      pdf.save('orcamento.pdf')
   }

   return (
      <div className='container mx-auto p-4'>
         <button
            className='mr-2 flex items-center rounded bg-blue-500 p-2 text-white'
            onClick={gerarImagem}
         >
            Gerar Imagem
         </button>
         {imgPreview && (
            <div className='mt-4'>
               <h3>Pré-visualização da Imagem:</h3>
               <img
                  src={imgPreview}
                  alt='Pré-visualização'
                  className='border border-gray-300'
               />
            </div>
         )}
         <div className='mb-4 block'>
            <button
               className='flex items-center rounded bg-red-500 p-2 text-white'
               onClick={gerarPDF}
            >
               <FaFilePdf className='mr-2' /> Imprimir PDF
            </button>
         </div>

         <div id='orcamento' className='impressao impressao-container'>
            <Header dados={dados} />
            <CustoMaterial orcamento={dados.orcamento} />
         </div>

         <div className='mt-4 hidden justify-end md:flex'>
            <button
               className='flex items-center rounded bg-red-500 p-2 text-white'
               onClick={gerarPDF}
            >
               <FaFilePdf className='mr-2' /> Imprimir PDF
            </button>
         </div>
      </div>
   )
}

export default Orcamento
